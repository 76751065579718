/** @jsxImportSource @emotion/react */
import { createContext, Dispatch, SetStateAction } from "react";

interface LoginDialogContext {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default createContext<LoginDialogContext>({
  isOpen: false,
  setIsOpen: () => {},
});

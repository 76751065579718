export const Z_INDEX = {
  layout: 3,
  overlay: 2,
  content: 1,
} as const

export const LAYOUT_PADDING = {
  pc: {
    default: {
      top: 40,
      right: 60,
      bottom: 100,
      left: 60,
    },
    notice: {
      top: 24,
      right: 60,
      bottom: 100,
      left: 60,
    },
  },
  mobile: {
    default: {
      top: 40,
      right: 24,
      bottom: 100,
      left: 24,
    },
    notice: {
      top: 24,
      right: 24,
      bottom: 100,
      left: 24,
    },
  },
} as const

export const HIGHEST_PRIORITY_STYLE = 9999999

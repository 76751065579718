import React, { useState } from "react";
import { css } from "@emotion/react";
import Button from "design-system/components/Button/Button";
import appleLogo from "resource/trainer/homeResource/apple_logo.png";
import playStoreLogo from "resource/trainer/homeResource/play_store_logo.png";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { useNavigate } from "react-router";
import LoginDialogContext from "../../common/login-dialog/loginDialogContext";
import { LoginDialog } from "../../common/login-dialog/LoginDialog";

export function TrainerFrame1() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const appleStore =
    "https://apps.apple.com/us/app/핏츠유-트레이너/id6504490224";
  const playStore =
    "https://play.google.com/store/apps/details?id=kr.co.fitsyou.app.trainer&hl=ko-KR";
  return (
    <div css={containerCSS}>
      <div css={emphasisTitleCSS}>
        나에게 딱 맞는 <br />
        PT 일지 관리 핏츠유
      </div>
      <div css={buttonContainerCSS}>
        <Button
          css={buttonCSS}
          onClick={() => window.open(appleStore, "_blank")}
        >
          <img src={appleLogo} css={logoCSS} />
          Apple Store
        </Button>
        <Button
          css={buttonCSS}
          onClick={() => window.open(playStore, "_blank")}
        >
          <img src={playStoreLogo} css={logoCSS} />
          Google Play
        </Button>
      </div>
      <div css={webButtonContainerCSS}>
        <Button
          css={webButtonCSS}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          웹으로 시작하기
        </Button>
      </div>
      <LoginDialogContext.Provider value={{ isOpen, setIsOpen }}>
        <LoginDialog />
      </LoginDialogContext.Provider>
    </div>
  );
}

const webButtonContainerCSS = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const webButtonCSS = css`
  border-radius: 2000px;
  padding: 16px 36px;
  border: 1px solid #fff;
  background: ${colors.lime600};
  ${typography.mobile.heading2}
`;

const containerCSS = css`
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const logoCSS = css`
  height: 22px;
  width: 22px;
`;

const buttonContainerCSS = css`
  display: flex;
  width: 100%;
  gap: 17px;
  justify-content: center;
`;

const buttonCSS = css`
  display: flex;
  gap: 14px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  color: white;
  padding: 16px 24px;
`;

const emphasisTitleCSS = css`
  background: linear-gradient(109deg, #83f9e9 -2.12%, #e0f569 111.08%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 137px;
`;

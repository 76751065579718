import React from "react";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { WorkoutIntensity } from "../../../api/common/commonEnumType";

const progressBarCSS = css`
  position: relative;
  height: 10px;
  background: linear-gradient(to right, #f7fcc4, #b7e863);
  border-radius: 5px;
  margin-top: 20px;
`;

const markerCSS = (position: number) => css`
  position: absolute;
  top: -15px;
  left: ${position}%;
  transform: translateX(-50%);
  font-size: 20px;
`;

const arrowCSS = css`
  font-size: 20px;
`;

const labelsCSS = css`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export interface IntensityGraphProps {
  intensity: WorkoutIntensity;
}

export function IntensityGraph({ intensity }: IntensityGraphProps) {
  // const [currentStep, setCurrentStep] = useState(2); // 현재 화살표 위치 단계 (0~4)
  const percent =
    intensity === "very_easy"
      ? 0
      : intensity === "easy"
        ? 25
        : intensity === "normal"
          ? 50
          : intensity === "hard"
            ? 75
            : 100;
  // 단계에 따라 화살표 위치 설정
  const position = percent;

  return (
    <>
      <div css={progressBarCSS}>
        <div css={markerCSS(position)}>
          <span css={arrowCSS}>▼</span>
        </div>
      </div>
      <div css={labelsCSS}>
        <span css={textCSS}>아주 쉬웠어요</span>
        <span css={textCSS}>적당했어요</span>
        <span css={textCSS}>아주 힘들었어요</span>
      </div>
    </>
  );
}

const textCSS = css`
  ${typography.mobile.body2}
`;

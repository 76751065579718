import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";

export const breakpoints = {
  default: 1600,
  pc: 980,
  mobile: 500,
} as const;

export const getWidthMediaQuery = (breakpoint: keyof typeof breakpoints) =>
  `@media (min-width: ${breakpoints[breakpoint]}px)`;

const useResponsiveRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the current path includes "crm"
    if (location.pathname.includes("/crm")) {
      // Set up a media query for screens 500px or smaller
      const mediaQuery = window.matchMedia(
        `(max-width: ${breakpoints.mobile}px)`,
      );

      // Redirect if the media query matches (screen width <= 500px)
      if (mediaQuery.matches) {
        navigate("/error", {
          state: {
            title:
              "모바일 환경에서는 '핏츠유 트레이너' APP 사용을 권장 드립니다.",
            subTitle: "'핏츠유 트레이너' APP 에서 더 많은 기능을 사용해보세요",
          },
        });
      }

      // Optionally add an event listener if you want this to respond to resizing
      const handleResize = (e: MediaQueryListEvent) => {
        if (e.matches) {
          navigate("/error", {
            state: {
              title:
                "모바일 환경에서는 '핏츠유 트레이너' APP 사용을 권장 드립니다.",
              subTitle:
                "'핏츠유 트레이너' APP 에서 더 많은 기능을 사용해보세요",
            },
          });
        }
      };

      mediaQuery.addEventListener("change", handleResize);

      // Clean up the event listener
      return () => {
        mediaQuery.removeEventListener("change", handleResize);
      };
    }
  }, [location, navigate]);
};

export default useResponsiveRedirect;

import {MainHeader} from "../../common/Header/MainHeader";
import {MainTitle} from "../../MainTitle";
import {ApplyButton} from "../../ApplyButton";
import React from "react";
import {css} from "@emotion/react";
import {getWidthMediaQuery} from "../../styles/mediaQueries";
import {fontFamily, fontWeight,} from "design-system/styles/typography/typography";
import {useWindowSize} from "../../hoock/useWindowSize";

/** @jsxImportSource @emotion/react */
export default function Frame1() {
  const { isPC } = useWindowSize();
  return (
    <div css={frame1ContainerCSS}>
      {isPC && <MainHeader />}
      <MainTitle />
      <ApplyButton />
      <div css={textDecoCSS}>
        <span css={textDecoTextCSS}>FitsYou</span>
      </div>
    </div>
  );
}

const frame1ContainerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 800px;

  ${getWidthMediaQuery("pc")} {
    padding: 0;
    height: 56.25vw;
  }
`;

const textDecoTextCSS = css`
  background-clip: text;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.33) 28.22%,
    rgba(255, 255, 255, 0.12) 84.98%
  );

  -webkit-background-clip: text;
  color: transparent;
  opacity: 0.2;

  text-align: center;
  font-style: normal;

  overflow: hidden;
  white-space: nowrap;
  font-family: ${fontFamily.natomPro}, sans-serif;
  font-weight: ${fontWeight.Bold};
  line-height: 120%;
  font-size: 28vw;
`;

const textDecoCSS = css`
  position: relative;
  align-self: center;
  bottom: 10vw;
  @media (min-width: 600px) {
    bottom: 15vw;
  }

  ${getWidthMediaQuery("pc")} {
    bottom: 19vw;
  }
`;

import React, { Suspense } from "react";
import { css, Global } from "@emotion/react";
import { globalStyles } from "./resource/global";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { TraineeApp } from "./trainee/TraineeApp";
import { TrainerApp } from "./trainer/TrainerApp";
import { CrmHome } from "./crm/CrmHome";
import ReactQueryClientProvider from "./provider/ReactQueryClientProvider";
import { StartPage } from "./trainer/start-page/StartPage";
import GlobalErrorBoundary from "./GlobalErrorBoundary";
import { Auth } from "./Auth";
import Loading from "design-system/components/Loading/Loading";
import { ReportListPage } from "./crm/report/list/Page";
import { ReportDetailPage } from "./crm/report/Page";
import { CrmReportWritePage } from "./crm/report/create/Page";
import { ErrorPage } from "./ErrorPage/ErrorPage";
import useResponsiveRedirect from "./styles/mediaQueries";
import { useAmplitude } from "./hoock/useAmplitude";

function AppRoutes() {
  useResponsiveRedirect();
  const amplitude = useAmplitude();
  amplitude.init();

  return (
    <GlobalErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<TrainerApp />}></Route>
          <Route path="/trainer/*" element={<TrainerApp />}></Route>
          <Route path="/trainee/*" element={<TraineeApp />}></Route>
          <Route path="/crm/*" element={<CrmHome />}></Route>
          <Route path="/auth/*" element={<Auth />}></Route>
          <Route path="*" element={<TraineeApp />}></Route>
          <Route path="/start-page" element={<StartPage />}></Route>
          <Route path="crm/report/list" element={<ReportListPage />} />
          <Route path="crm/report" element={<ReportDetailPage />} />
          <Route path="error" element={<ErrorPage />} />
          <Route path="crm/report/create" element={<CrmReportWritePage />} />
        </Routes>
      </Suspense>
    </GlobalErrorBoundary>
  );
}

function App() {
  return (
    <div
      className="App"
      css={css`
        height: auto;
        min-height: 100vh;
      `}
    >
      <Global styles={globalStyles} />
      <ReactQueryClientProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ReactQueryClientProvider>
    </div>
  );
}

export default App;

import { ReportType, WorkoutParts } from "../../common/commonEnumType";
import { WorkoutDetailDTO, WorkoutSetDTO } from "../users/usersTypes";
import { AttachFileDTO } from "../../common/commonTypes";

export type PostSchedulesScheduleSlotIdLessonsRequest =
  RequestSaveLessonRequestDTO & {
    scheduleSlotId: number;
  };

export type PostSchedulesScheduleSlotIdLessonsResponse = {
  lessonId: number;
};

export type getSchedulesScheduleSlotIdUsersUserIdTicketResponse =
  TicketTicketInfoDTO;

export type getSchedulesScheduleSlotIdUsersUserIdTicketRequest = {
  scheduleSlotId: number;
  userId: number;
};

export interface TicketTicketInfoDTO {
  id: number;
  trainerId: number;
  traineeName: string;
  startAt: string;
  endAt?: string;
  centerName?: string;
  totalCount: number;
  usedCount: number;
  status: string;
  price?: number;
  otherInfo?: string;
  code?: string;
}

export interface RequestSaveLessonRequestDTO {
  userId: number;
  ticketId: number;
  lessonResultType: ReportType;
  workoutTime?: number;
  totalFeedback?: string;
  workoutDetails?: RequestSaveLessonRequestWorkoutDetailDTO[];
}

export interface RequestSaveLessonRequestWorkoutDetailDTO {
  id?: number;
  isCustom?: boolean;
  workoutName: string;
  part: WorkoutParts;
  sets: WorkoutSetDTO[];
  images: string[];
  videos: string[];
  feedback: string;
}

/*
예외적으로 섞어씀
 */
export function workoutDetailTORequestSaveLessonRequestWorkoutDetailDTO(
  detail: WorkoutDetailDTO,
): MixedWorkoutDetail {
  return {
    id: detail.id,
    isCustom: detail.isCustom,
    workoutName: detail.workoutName, // 대소문자 수정 필요
    part: detail.part,
    sets: detail.sets,
    images: detail.images.map((image) => image.url), // AttachFileDTO의 filePath로 변환
    videos: detail.videos.map((video) => video.url), // AttachFileDTO의 filePath로 변환
    feedback: detail.feedback || "",
    thumbnail: detail.thumbnail,
    imagesDetail: detail.images,
    videosDetail: detail.videos,
  };
}

export type MixedWorkoutDetail = RequestSaveLessonRequestWorkoutDetailDTO & {
  thumbnail?: string;
  imagesDetail: CustomAttachFileDTO[];
  videosDetail: CustomAttachFileDTO[];
};

export type CustomAttachFileDTO = AttachFileDTO & { file?: File };

import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  ReactNode,
  TextareaHTMLAttributes,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { css, Interpolation, Theme } from "@emotion/react";

const Textarea = forwardRef<
  HTMLTextAreaElement,
  TextareaHTMLAttributes<HTMLTextAreaElement> & {
    styles?: Interpolation<Theme>;
    isError?: boolean;
    isValidated?: boolean;
    isLoading?: boolean;
    prefixContent?: ReactNode;
    suffixContent?: ReactNode;
    isShowCount?: boolean;
    isAutoHeight?: boolean;
    isPreventNewline?: boolean;
    maxLengthCount?: number;
  }
>(
  (
    {
      value,
      disabled,
      rows,
      styles,
      isError,
      isValidated,
      isLoading,
      prefixContent,
      suffixContent,
      isShowCount,
      maxLength,
      maxLengthCount,
      isAutoHeight = true,
      isPreventNewline,
      onChange,
      onKeyDown,
      style,
      ...props
    },
    ref,
  ) => {
    // const hiddenTextareaRef = useRef<HTMLTextAreaElement>(null);
    const [autoHeight, setAutoHeight] = useState<number>(0);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {}, [autoHeight]);

    useLayoutEffect(() => {
      if (isAutoHeight && textAreaRef.current) {
        // 입력 내용에 따라 scrollHeight 기반으로 높이를 동적으로 설정
        // textAreaRef.current.style.height = "auto"; // 높이를 초기화하여 scrollHeight 계산
        //   textAreaRef.current.style.height = "auto"; // 높이를 초기화하여 scrollHeight 계산
        setAutoHeight(textAreaRef.current.scrollHeight);
      }
    }, [value]);

    const onKeyDownHandling = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (isPreventNewline && e.key === "Enter") {
        e.preventDefault();
      }

      onKeyDown?.(e);
    };

    const onChangeHandling = (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (isPreventNewline) {
        e.target.value = e.target.value.replace(/[\n\r\u2028]/g, "");
      }

      onChange?.(e);
    };

    return (
      <label
        css={[textareaWrapperCSS, styles]}
        data-disabled={disabled}
        data-state={isError && "error"}
      >
        <div css={innerWrapperCSS}>
          {prefixContent}
          <textarea
            css={textareaCSS}
            disabled={disabled}
            maxLength={maxLength}
            ref={textAreaRef}
            rows={isAutoHeight ? 1 : rows}
            value={value}
            style={
              isAutoHeight && autoHeight > 0
                ? { ...style, height: `${autoHeight}px` } // 높이 설정을 픽셀 단위로 적용
                : { ...style }
            }
            onKeyDown={onKeyDownHandling}
            onChange={onChangeHandling}
            {...props}
          />
          {suffixContent}
          {/*{isValidated && !isError && !isLoading && (*/}
          {/*  <span css={iconWrapperCSS}>*/}
          {/*    <PoDone css={doneIconCSS} />*/}
          {/*  </span>*/}
          {/*)}*/}
          {/*{isLoading && !isError && (*/}
          {/*  <span css={iconWrapperCSS}>*/}
          {/*    <Lottie json={pfLoadingBlue} styles={loadingLottieCSS} />*/}
          {/*  </span>*/}
          {/*)}*/}
          {isAutoHeight && (
            <textarea
              // ref={hiddenTextareaRef}
              css={[textareaCSS, autoHeightCSS]}
              disabled={disabled}
              maxLength={maxLength}
              // rows={1}
              value={value}
              style={style}
              aria-hidden="true"
              onKeyDown={onKeyDownHandling}
              onChange={onChangeHandling}
              {...props}
            />
          )}
        </div>
        {isShowCount && (maxLength || maxLengthCount) && (
          <div css={bottomWrapperCSS}>
            <span css={innerContentCSS}>
              {value?.toString().length || 0}/{maxLength || maxLengthCount}
            </span>
          </div>
        )}
      </label>
    );
  },
);

Textarea.displayName = "Textarea";

export { Textarea };

const textareaWrapperCSS = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11px 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${colors.default.Gray600};
  background-color: white;
  gap: 8px;

  &[data-disabled="true"] {
    cursor: not-allowed;
    border: 1px solid ${colors.default.Gray800};
  }
`;

const textareaCSS = css`
  display: flex;
  width: 100%;
  border: 0;
  padding: 0;
  resize: none;
  background-color: transparent;
  color: ${colors.gray800};
  ${typography.mobile.body2};

  :disabled {
    cursor: not-allowed;
    color: ${colors.default.Gray700};

    ::placeholder {
      color: ${colors.default.Gray700};
    }
  }

  ::placeholder {
    color: ${colors.default.Gray300};
  }
`;

const innerWrapperCSS = css`
  display: flex;
  align-items: center;
  width: 100%;
`;

const bottomWrapperCSS = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const innerContentCSS = css`
  color: ${colors.default.Gray300};
  ${typography.Button2};

  :disabled {
    cursor: not-allowed;
    color: ${colors.default.Gray700};
  }
`;

const autoHeightCSS = css`
  height: auto;
  visibility: hidden;
  position: absolute;
`;

import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useContext,
} from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import TabsContext from "design-system/components/Tabs/tabsContext";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { css, Interpolation, Theme } from "@emotion/react";

type TabsVariant = "default" | "underline";

export type TabsRootProps = TabsPrimitive.TabsProps & {
  variant?: TabsVariant;
};

const Root = forwardRef<
  ElementRef<typeof TabsPrimitive.Root>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Root> & {
    variant?: TabsVariant;
    styles?: Interpolation<Theme>;
  }
>(({ variant = "default", styles, ...props }, ref) => (
  <TabsContext.Provider value={{ variant }}>
    <TabsPrimitive.Root
      ref={ref}
      css={[variant === "default" && tabsRootDefaultCSS, styles]}
      {...props}
    />
  </TabsContext.Provider>
));

Root.displayName = TabsPrimitive.Root.displayName;

const List = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => {
  const tabsContext = useContext(TabsContext);

  return (
    <TabsPrimitive.List
      ref={ref}
      css={[
        tabsListCSS,
        tabsContext?.variant === "underline" && tabsListUnderlineCSS,
        styles,
      ]}
      {...props}
    />
  );
});

List.displayName = TabsPrimitive.List.displayName;

const Trigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => {
  const tabsContext = useContext(TabsContext);

  return (
    <TabsPrimitive.Trigger
      ref={ref}
      css={[
        tabsTriggerCSS,
        tabsContext?.variant === "underline"
          ? tabsTriggerUnderlineCSS
          : tabsTriggerDefaultCSS,
        styles,
      ]}
      {...props}
    />
  );
});

Trigger.displayName = TabsPrimitive.Trigger.displayName;

const Content = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <TabsPrimitive.Content ref={ref} css={[contentCSS, styles]} {...props} />
));

Content.displayName = TabsPrimitive.Content.displayName;

export { Root, List, Trigger, Content };

const tabsRootDefaultCSS = css`
  gap: 24px;
`;

const tabsListCSS = css`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  width: fit-content;
  max-width: 100%;
  overflow-x: auto;
`;

const tabsListUnderlineCSS = css`
  gap: 0;
`;

const tabsTriggerCSS = css`
  position: relative;
  width: 100%;
  display: inline-flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;
  color: ${colors.default.Gray500};
  cursor: pointer;
`;

const tabsTriggerDefaultCSS = css`
  ${typography.Tab};

  :active {
    color: ${colors.gray800};
  }

  :disabled {
    cursor: not-allowed;
  }

  &:not(:last-child) {
    ::after {
      content: "";
      position: absolute;
      background-color: ${colors.default.Black_a10};
      width: 1px;
      height: 17px;
      right: -16px;
    }
  }

  &[data-state="active"] {
    color: ${colors.gray800};

    :disabled {
      color: ${colors.gray100};
    }
  }

  &[data-state="inactive"] {
    :disabled {
      color: ${colors.default.Gray700};
    }
  }
`;

const tabsTriggerUnderlineCSS = css`
  height: 40px;
  padding: 0px 16px;
  border: none;
  border-bottom: 1px solid ${colors.default.Gray500};
  ${typography.Button2};

  :active {
    color: ${colors.default.Black};
    border-bottom: 2px solid ${colors.default.Black};
  }

  &[data-state="active"] {
    color: ${colors.default.Black};
    border-bottom: 2px solid ${colors.default.Black};
  }
`;

const contentCSS = css`
  &[data-state="active"] {
    margin-top: 24px;
  }
`;

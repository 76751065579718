import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import Button from "design-system/components/Button/Button";
import { useLocation, useNavigate } from "react-router";
import { CrmHeader } from "../common/Header/CrmHeader";
import React from "react";
import infoImage from "design-system/styles/assets/images/info.png";

export function ErrorPage() {
  const location = useLocation();
  const { title, subTitle, isLogout } = location.state || {
    title: "",
    subTitle: "",
  };
  const navigate = useNavigate();

  return (
    <>
      <CrmHeader logoutDisable={true} />
      <div css={errorPageCSS}>
        <img src={infoImage} css={infoImageCSS} />
        <h3 css={errorTitleCSS}>{title}</h3>
        <span css={errorTextCSS}>{subTitle}</span>
        <Button
          variant={"primary"}
          size={46}
          styles={errorButtonCSS}
          onClick={() => navigate("/")}
        >
          홈으로 이동
        </Button>
      </div>
    </>
  );
}

const infoImageCSS = css`
  height: 40px;
  width: 40px;
  object-fit: contain;
`;

const errorPageCSS = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  background: ${colors.gray25};
`;

const errorTitleCSS = css`
  margin-top: 32px;
  ${typography.Body3};
  text-align: center;
`;

const errorTextCSS = css`
  margin-top: 8px;
  ${typography.Body2};
  text-align: center;
  ${typography.Button1};
  color: ${colors.gray400};
`;

const errorButtonCSS = css`
  margin-top: 32px;
  width: 312px;
`;

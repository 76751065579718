import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { CrmHeader } from "../common/Header/CrmHeader";
import { useNavigate } from "react-router";
import { EventName, ScreenName, useAmplitude } from "../hoock/useAmplitude";

export function CrmHome() {
  const navigate = useNavigate();

  const hasSentLog = useRef(false);
  const { sendScreenViewEvent } = useAmplitude();

  useEffect(() => {
    if (!hasSentLog.current) {
      sendScreenViewEvent({
        eventName: EventName.web_crm_home_main_screen_viewed,
        screenName: ScreenName.web_crm_home_main,
      });
    }
  });

  useEffect(() => {
    navigate("/crm/report/list");
  }, []);

  return (
    <div css={containerCSS}>
      <CrmHeader />
      <div css={contentsContainerCSS}></div>
    </div>
  );
}

const contentsContainerCSS = css`
  display: flex;
  width: 100%;
  height: 100%;
`;

const containerCSS = css`
  background: ${colors.gray25};
  width: 100%;
  height: 100%;
`;

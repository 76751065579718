import { css } from "@emotion/react";
import React, { useEffect, useRef } from "react";
import { MainHeader } from "../common/Header/MainHeader";
import { useSearchParams } from "react-router-dom";
import { customFetch } from "../libs/fetch/fetch";
import upperBG from "resource/trainer/homeResource/home_bg_upper.png";
import middleBG from "resource/trainer/homeResource/home_bg_middle.png";
import { TrainerFrame1 } from "./frame1/TrainerFrame1";
import { Frame4 } from "../trainee/Frame4/Frame4";
import Frame5 from "../trainee/frame5/Fram5";
import { DescriptionFrame } from "./DescriptionFrame";
import { EventName, ScreenName, useAmplitude } from "../hoock/useAmplitude";

export function TrainerApp() {
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const hasSentLog = useRef(false);
  const { sendScreenViewEvent } = useAmplitude();

  useEffect(() => {
    if (!hasSentLog.current) {
      sendScreenViewEvent({
        eventName: EventName.web_home_main_screen_viewed,
        screenName: ScreenName.web_home_main,
      });
    }
  });

  useEffect(() => {
    if (state == "logout") {
      customFetch(
        {
          app: "user",
          version: "v1",
          path: `/logout`,
        },
        { method: "POST" },
      );
    }
  }, [state]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        background: black;
      `}
    >
      <MainHeader />
      <div css={containerCSS}>
        <div css={upperContentCSS}>
          <img src={upperBG} css={upperImageCSS} />
          <img src={middleBG} css={imageCSS} />
        </div>
        <div css={bottomContainerCSS}>
          <TrainerFrame1 />
          <DescriptionFrame />
          <div css={BottomDecoContainerCSS}>
            <Frame4 />
            <Frame5 />
          </div>
        </div>
      </div>
    </div>
  );
}

const BottomDecoContainerCSS = css`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const middleContentCSS = css`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const upperContentCSS = css`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const bottomContainerCSS = css`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const upperImageCSS = css`
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

const imageCSS = css`
  object-fit: contain;
  z-index: -1;
  width: 100%;
`;

import {
  getSchedulesScheduleSlotIdUsersUserIdTicketRequest,
  getSchedulesScheduleSlotIdUsersUserIdTicketResponse,
  PostSchedulesScheduleSlotIdLessonsRequest,
  PostSchedulesScheduleSlotIdLessonsResponse,
} from "./schedulesTypes";
import { customFetch } from "../../../libs/fetch/fetch";

export async function postSchedulesScheduleSlotIdLessons({
  scheduleSlotId,
  ...request
}: PostSchedulesScheduleSlotIdLessonsRequest): Promise<PostSchedulesScheduleSlotIdLessonsResponse> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/schedules/${scheduleSlotId}/lessons`,
    },

    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}

export async function putSchedulesScheduleSlotIdLessons({
  scheduleSlotId,
  ...request
}: PostSchedulesScheduleSlotIdLessonsRequest): Promise<PostSchedulesScheduleSlotIdLessonsResponse> {
  return await customFetch(
    {
      app: "trainer",
      version: "v1",
      path: `/schedules/${scheduleSlotId}/lessons`,
    },

    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...request }),
    },
  );
}

export async function getSchedulesScheduleSlotIdUsersUserIdTicket({
  scheduleSlotId,
  userId,
}: getSchedulesScheduleSlotIdUsersUserIdTicketRequest): Promise<getSchedulesScheduleSlotIdUsersUserIdTicketResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/schedules/${scheduleSlotId}/users/${userId}/ticket`,
  });
}

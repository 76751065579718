import { css } from "@emotion/react";
import {
  fontFamily,
  fontWeight,
} from "design-system/styles/typography/typography";
import { useState } from "react";
import { LoginDialog } from "../login-dialog/LoginDialog";
import LoginDialogContext from "../login-dialog/loginDialogContext";
import { EventName, ScreenName, useAmplitude } from "../../hoock/useAmplitude";

export function MainHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const { sendClickEvent } = useAmplitude();

  return (
    <div css={containerCSS}>
      <div css={innerContainerCSS}>
        <span css={headerCSS}>FitsYou</span>
        <div css={gnbContainerCSS}>
          <div
            css={loginButtonContainer}
            onClick={() => {
              sendClickEvent({
                eventName: EventName.web_login_button_clicked,
                screenName: ScreenName.web_home_main,
              });
              setIsOpen(!isOpen);
            }}
          >
            <span css={loginButtonTextCSS}>로그인</span>
          </div>
        </div>
      </div>
      <LoginDialogContext.Provider value={{ isOpen, setIsOpen }}>
        <LoginDialog />
      </LoginDialogContext.Provider>
    </div>
  );
}

const gnbContainerCSS = css`
  display: flex;
  gap: 15px;
`;

const loginButtonTextCSS = css`
  color: white;
  font-size: 24px;
  font-weight: ${fontWeight.Medium};
  line-height: 36px;
`;

const loginButtonContainer = css`
  padding: 10px 24px;
  border-radius: 4px;
  min-width: 140px;
  cursor: pointer;
`;

const containerCSS = css`
  z-index: 3;
  position: relative;
  padding: 40px 180px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.18);
`;

const headerCSS = css`
  color: #fff;
  font-size: 30px;
  font-family: ${fontFamily.natomPro}, sans-serif;
  font-weight: ${fontWeight.Bold};
  min-width: 130px;
`;

const innerContainerCSS = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

import { WorkoutParts } from "./commonEnumType";

export interface PostUploadsPresignedRequest {
  type:
    | "trainer_cover"
    | "trainer_main"
    | "trainer_qualification"
    | "user_inbody"
    | "user_review"
    | "user_workout_image"
    | "user_workout_video";
  contentType: "jpeg" | "png" | "mp4" | "mov";
  thumbnailContentType?: "jpeg" | "png" | "mp4";
}

export type PostUploadsPresignedResponse = AttachFileDTO;

export interface AttachFileDTO {
  id: string;
  url: string;
  thumbnail?: string;
}

export type GetWorkoutsResponse = WorkoutCategoriesDTO;

export interface WorkoutCategoriesDTO {
  lowerBody: WorkoutCategoriesDetailDTO[];
  chest: WorkoutCategoriesDetailDTO[];
  shoulders: WorkoutCategoriesDetailDTO[];
  back: WorkoutCategoriesDetailDTO[];
  arms: WorkoutCategoriesDetailDTO[];
  abs: WorkoutCategoriesDetailDTO[];
  etc: WorkoutCategoriesDetailDTO[];
  custom: WorkoutCategoriesDetailDTO[];
}

export const mapPartToKey = (
  part: WorkoutParts,
): keyof WorkoutCategoriesDTO => {
  const mapping: Record<WorkoutParts, keyof WorkoutCategoriesDTO> = {
    lower_body: "lowerBody",
    chest: "chest",
    back: "back",
    shoulders: "shoulders",
    arms: "arms",
    abs: "abs",
    etc: "etc",
    custom: "custom",
  };

  return mapping[part];
};

export interface WorkoutCategoriesDetailDTO {
  id: number;
  codeName?: string;
  part: WorkoutParts;
  name: string;
  imagePath?: string;
  thumbnail?: string;
  description?: string;
}

interface RequestSaveCustomWorkoutRequestDTO {
  workoutPart: WorkoutParts;
  workoutName: string;
}

export type PostWorkoutCustomRequest = RequestSaveCustomWorkoutRequestDTO;

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";

export function Separator() {
  return <div css={containerCSS}></div>;
}

const containerCSS = css`
  background: ${colors.gray100};
  height: 1px;
  width: 100%;
`;

import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../libs/react-query";
import { getUsersUserIdLessonsLessonId } from "../../api/trainer/users/users";
import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { CrmHeader } from "../../common/Header/CrmHeader";
import { Sidebar } from "../Sidebar/Sidebar";
import React, { useState } from "react";
import {
  CrmContentHorizontalPadding,
  CrmContentVerticalPadding,
} from "../component/crmCSS";
import { typography } from "design-system/styles/typography/typography";
import { workoutPartsInKR } from "../../api/common/commonEnumType";
import { TotalFeedback } from "./component/TotalFeedback/TotalFeedback";
import { TraineeFeedback } from "./component/TraineeFeedback/TraineeFeedback";
import { IntensityEvaluation } from "./component/IntensityEvaluation/IntensityEvaluation";
import { WorkoutContainer } from "./component/Workout/WorkoutContainer";
import Button from "design-system/components/Button/Button";
import { ReportShareDialog } from "./component/ReportShareDialog/ReportShareDialog";
import { ScreenName } from "../../hoock/useAmplitude";

export function ReportDetailPage() {
  const [searchParams] = useSearchParams();
  const lessonId = parseInt(searchParams.get("lessonId") || "0", 10);
  const userId = parseInt(searchParams.get("userId") || "0", 10);
  const scheduleSlotId = parseInt(
    searchParams.get("scheduleSlotId") || "0",
    10,
  );
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);

  const { data: lessonData } = useQuery({
    queryFn: () =>
      getUsersUserIdLessonsLessonId({
        lessonId,
        userId,
      }),
    queryKey: queryKeys.getUsersUserIdLessonsLessonId({
      lessonId,
      userId,
    }),
  });

  const date = lessonData?.lessonAt ? new Date(lessonData.lessonAt) : undefined;
  let partsString = lessonData?.workoutParts
    .map((part) => workoutPartsInKR(part)) // 영문을 한글로 변환
    .join(", "); // 콤마로 구분해서 하나의 문자열로 합침

  return (
    <div css={containerCSS}>
      <CrmHeader />
      <div css={contentsContainerCSS}>
        <Sidebar />
        <div css={contentContainerCSS}>
          {/*HEAER*/}
          <div css={contentHeaderContainerCSS}>
            <div css={summaryUpperContainerCSS}>
              <div css={dateContainer}>
                {date && <>{date.getFullYear()}년 </>}
                {date && <>{date.getMonth() + 1}월 </>}
                {date && <>{date.getDate()}일</>}
              </div>
              <Button
                onClick={() => setIsShareDialogOpen(true)}
                size={46}
                css={buttonCSS}
              >
                카카오톡 공유하기
              </Button>
            </div>
            {lessonData?.lessonResultType !== "feedback_only" && (
              <div css={summaryLowerContainerCSS}>
                <span css={summaryTitleTextCSS}>PT일지 요약</span>
                <div css={summaryContainerCSS}>
                  <span css={summaryTitleBodyTextCSS}>레슨</span>
                  <span css={summaryBodyContentTextCSS}>
                    {partsString} 운동
                  </span>
                </div>
              </div>
            )}
          </div>
          {/*BODY*/}
          {lessonData &&
            lessonData.workoutDetails.length !== 0 &&
            WorkoutContainer({
              workoutList: lessonData.workoutDetails,
              userId,
            })}
          {lessonData && (
            <div css={reportBodyContainerCSS}>
              <div css={workoutListContainerCSS}></div>
              <TotalFeedback
                trainerName={lessonData.trainerName}
                trainerImg={lessonData.trainerImageUrl}
                feedback={lessonData.totalFeedback}
              />
              {lessonData.workoutIntensity && date && (
                <IntensityEvaluation
                  intensity={lessonData.workoutIntensity}
                  date={date}
                />
              )}
              {lessonData.oneLineReview && (
                <TraineeFeedback feedback={lessonData.oneLineReview} />
              )}
            </div>
          )}
        </div>
      </div>
      {lessonData && (
        <ReportShareDialog
          isOpen={isShareDialogOpen}
          setIsOpen={setIsShareDialogOpen}
          lessonData={lessonData}
          userId={userId}
          screenName={ScreenName.web_pt_report_detail}
          scheduleSlotId={scheduleSlotId}
        />
      )}
    </div>
  );
}

const buttonCSS = css`
  background: ${colors.gray800};
  color: white;
`;

const workoutListContainerCSS = css``;
const reportBodyContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const summaryTitleTextCSS = css`
    ${typography.mobile.heading1}
}
`;

const summaryBodyContentTextCSS = css`
    ${typography.mobile.heading2}
}
`;

const summaryTitleBodyTextCSS = css`
    ${typography.mobile.body2}
}`;

const dateContainer = css`
  display: flex;
  align-items: center;
`;

const summaryContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const summaryLowerContainerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 16px;
  background: ${colors.lightGray};
  border-radius: 8px;
`;

const summaryUpperContainerCSS = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${typography.mobile.heading1}
  gap: 16px;
  color: ${colors.default.Gray};
`;

const contentHeaderContainerCSS = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const contentContainerCSS = css`
  padding: ${CrmContentVerticalPadding}px ${CrmContentHorizontalPadding}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-height: 100vh;
  height: auto;
`;

const contentsContainerCSS = css`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: auto;
`;

const containerCSS = css`
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: auto;
`;

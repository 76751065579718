import * as Dialog from "../../design-system/components/Dialog/Dialog";
import closeIcon from "resource/common/icon/ic_close.svg";
import logoImage from "resource/common/logo.png";
import kakaoImage from "resource/common/kakao_login_large_wide.png";
import { css } from "@emotion/react";
import {
  fontFamily,
  fontWeight,
  typography,
} from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import { useContext } from "react";
import LoginDialogContext from "./loginDialogContext";
import { EventName, ScreenName, useAmplitude } from "../../hoock/useAmplitude";

export interface LoginDialogProps {
  screenName?: ScreenName;
}

export function LoginDialog({
  screenName = ScreenName.web_home_main,
}: LoginDialogProps) {
  const { sendClickEvent } = useAmplitude();
  const kakaoLoginButtonClick = () => {
    sendClickEvent({
      eventName: EventName.web_kakao_login_button_clicked,
      screenName: screenName,
    });
    const rest_api_key = process.env.REACT_APP_KAKAO;
    const redirect_uri = process.env.REACT_APP_KAKAO_AUTH_REDIRECT_URI;
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;
    window.location.href = kakaoURL;
  };

  const appleLoginButtonClick = () => {
    console.log("apple login");
  };

  const { isOpen, setIsOpen } = useContext(LoginDialogContext);
  return (
    <Dialog.Root variant={"page"} open={isOpen}>
      <Dialog.Content>
        <div css={loginPopupContainerCSS}>
          <div css={loginPopupHeaderContainerCSS}>
            <img
              src={closeIcon}
              width={38}
              height={38}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          </div>
          <div css={loginPopupBodyContainerCSS}>
            <img
              src={logoImage}
              width={56}
              height={56}
              alt={"logo image"}
            ></img>
            <div
              css={css`
                margin-top: 12px;
              `}
            >
              <span css={logoTextCSS}>FitsYou</span>
            </div>
            <div
              css={css`
                margin-top: 19px;
                width: 100%;
              `}
            >
              <span css={logoDescriptionTitleCSS}>
                아래 경우 모바일 App을 이용해주세요
              </span>
              <br />

              <div css={logoDescriptionBodyCSS}>
                <li>서비스 회원가입 (트레이너/수강생)</li>
                <li>수강생 로그인</li>
              </div>
            </div>
            <div
              css={css`
                margin-top: 90px;
              `}
              onClick={kakaoLoginButtonClick}
            >
              <button css={loginButtonCSS}></button>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}

const loginButtonCSS = css`
  width: 100%;
  background-image: url(${kakaoImage});
  background-size: cover; /* 이미지를 버튼 크기에 맞게 조절 */
  background-position: center; /* 이미지를 중앙에 위치 */
  border: none; /* 버튼 테두리를 제거 */
  height: 54px;
  cursor: pointer;
`;

const logoDescriptionTitleCSS = css`
    style: ${typography.Body3}
    font-size: 22px;
    color: ${colors.gray800};
    width: 100%;
`;

const logoDescriptionBodyCSS = css`
    margin-left: 15px;
    margin-top: 5px;
    style: ${typography.Body4}
    color: ${colors.gray800}
`;

const logoTextCSS = css`
  font-size: 54px;
  font-family: ${fontFamily.natomPro}, sans-serif;
  font-weight: ${fontWeight.Bold};
  color: black;
`;

const loginPopupBodyContainerCSS = css`
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

const loginPopupHeaderContainerCSS = css`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const loginPopupContainerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

import { useLayoutEffect, useState } from "react";
import { breakpoints } from "../styles/mediaQueries";

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isMobile = windowSize.width < breakpoints["pc"];
  const isPC = windowSize.width >= breakpoints["pc"];
  const isDefault = windowSize.width >= breakpoints["default"];

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", handleSize);

    return () => window.removeEventListener("resize", handleSize);
  }, []);

  return {
    width: windowSize.width,
    height: windowSize.height,
    isMobile,
    isPC,
    isDefault,
  };
}

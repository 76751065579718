import {
  ComponentPropsWithRef,
  ReactNode,
  Suspense,
  SyntheticEvent,
  useState,
} from "react";
import * as Dialog from "design-system/components/Dialog/Dialog";
import Lottie from "design-system/components/atom/Lottie/Lottie";
import { SuspenseImg } from "design-system/components/SuspenseImg/SuspenseImg";
import { pfLoadingBlack } from "design-system/styles/assets/lotties";
import errorImageVariantBox from "design-system/styles/assets/images/gr_coverDefault.png";
import { css } from "@emotion/react";
import playImage from "design-system/styles/assets/images/play.png";

interface ImageViewProps extends ComponentPropsWithRef<typeof SuspenseImg> {
  variant?: "box" | "circle" | "square";
  type?: "image" | "video";
  isShowBoxShadow?: boolean;
  videoSrc?: string;
  fallback?: ReactNode;
  isClickable?: boolean;
}

/**
 * TODO 동영상 대응 필요
 */

export function ImageView({
  variant = "box",
  type = "image",
  isShowBoxShadow = true,
  fallback,
  styles,
  videoSrc = "",
  isClickable = true,
  onError,
  ...props
}: ImageViewProps) {
  const [isOpen, setIsOpen] = useState(false);
  const onErrorHandling = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    if (variant === "box") {
      event.currentTarget.src = errorImageVariantBox;
    } else if (variant === "circle") {
      event.currentTarget.src = errorImageVariantBox;
    } else if (variant === "square") {
      event.currentTarget.src = errorImageVariantBox;
    }
    onError?.(event);
  };

  const ImageViewFallback = ({ variant }: Pick<ImageViewProps, "variant">) => {
    return (
      <div
        css={[
          imageViewFallbackCSS,
          variant === "box" && suspenseVariantBoxCSS,
          variant === "circle" && suspenseVariantCircleCSS,
          variant === "square" && suspenseVariantSquareCSS,
          styles,
        ]}
      >
        <Lottie
          data-indicator
          json={pfLoadingBlack}
          styles={imageViewFallbackLottieCSS}
        />
      </div>
    );
  };

  return (
    <Suspense fallback={fallback || <ImageViewFallback variant={variant} />}>
      <Dialog.Root variant={"page"} open={isOpen}>
        <Dialog.Content
          css={css`
            width: auto;
            min-width: 0;
            max-width: 1000px;
          `}
          onPointerDownOutside={() => setIsOpen(false)}
          // css={css`
          //   width: 400px;
          //   height: 400px;
          // `}
        >
          {type == "image" && <img src={props.src} css={resourceCSS} />}
          {type == "video" && (
            <video controls src={videoSrc} css={resourceCSS} />
          )}
        </Dialog.Content>
      </Dialog.Root>
      <div
        css={wrapper}
        onClick={() => {
          isClickable && setIsOpen(!isOpen);
        }}
      >
        <SuspenseImg
          css={[
            suspenseImageCSS,
            variant === "box" && suspenseVariantBoxCSS,
            variant === "circle" && suspenseVariantCircleCSS,
            variant === "square" && suspenseVariantSquareCSS,
            styles,
          ]}
          onError={onErrorHandling}
          {...props}
        />
        {isShowBoxShadow && (
          <div
            css={[
              shadow,
              variant === "box" && suspenseVariantBoxCSS,
              variant === "circle" && suspenseVariantCircleCSS,
              variant === "square" && suspenseVariantSquareCSS,
              styles,
            ]}
          />
        )}
        {type == "video" && (
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              display: flex; /* Flexbox 설정 */
              justify-content: center; /* 가로 가운데 정렬 */
              align-items: center; /* 세로 가운데 정렬 */
              height: 100%;
            `}
          >
            <img
              src={playImage}
              css={css`
                width: 50px;
                height: 50px;
              `}
            />
          </div>
        )}
      </div>
    </Suspense>
  );
}

const resourceCSS = css`
  max-width: 800px;
  max-height: 600px;
  object-fit: contain;
`;

const wrapper = css`
  position: relative;
  cursor: pointer;
`;

const shadow = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const suspenseImageCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  object-fit: cover;
`;

const suspenseVariantBoxCSS = css`
  width: 120px;
  height: 160px;
  border-radius: 8px;

  [data-indicator] {
    width: 26%;
  }
`;

const suspenseVariantCircleCSS = css`
  width: 80px;
  height: 80px;
  border-radius: 40px;

  [data-indicator] {
    width: 33%;
  }
`;

const suspenseVariantSquareCSS = css`
  width: 120px;
  height: 120px;
  border-radius: 8px;

  [data-indicator] {
    width: 26%;
  }
`;

const imageViewFallbackCSS = css`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`;

const imageViewFallbackLottieCSS = css`
  height: fit-content;
  flex-shrink: 0;
  line-height: 0;
`;

export const imageViewVariants = {
  box: suspenseVariantBoxCSS,
  circle: suspenseVariantCircleCSS,
  square: suspenseVariantSquareCSS,
  fallbackIcon: imageViewFallbackLottieCSS,
};

import {css} from "@emotion/react";
import bgUppper from "../../resource/trainee/frame3BgGradation.png";
import bgUppperMobile from "../../resource/trainee/fram3BgGradation2.png";
import bgLowerImg from "../../resource/trainee/frame3bgImage.png";
import {useWindowSize} from "../../hoock/useWindowSize";
import {fontWeight} from "design-system/styles/typography/typography";
import {QnaSection, QnaSectionProps} from "./QnaSection";
import {getWidthMediaQuery} from "../../styles/mediaQueries";

/** @jsxImportSource @emotion/react */
export default function Frame3() {
  const { isMobile, isPC } = useWindowSize();

  const qna1: QnaSectionProps = {
    question: "Q 신청시 비용은 얼마인가요?",
    answer:
      "핏츠유에서는 회원님에게 아무런 비용을 받지 않아요.\n" +
      "지금 바로 무료로 신청해보세요!",
  };
  const qna2: QnaSectionProps = {
    question: "Q 어떤 방식으로 추천해주시는지 궁금해요",
    answer:
      "회원님이 작성해주신 내용을 바탕으로 핏츠유에서 관리하고 있는 검증된 선생님을 추천해드려요.\n" +
      "쉽고 빠르게 검증된 트레이너님을 만나보세요 ",
  };
  const qna3: QnaSectionProps = {
    question: "Q 소요시간은 얼마나 되나요?",
    answer:
      "최대 2일의 시간이 걸려요.\n" +
      "회원님의 운동 목적에 맞는 최적의 선생님을 찾기 위해 걸리는 시간이에요.",
  };

  return (
    <div css={containerCSS}>
      <div css={qnaContainerCSS}>
        {isPC && <img src={bgUppper} css={imgCSS} alt={""} />}
        {isMobile && <img src={bgUppperMobile} css={imgCSS} alt={""} />}
        <div css={qnaContentContainerCSS}>
          <span css={qnaTitleTextCSS}>자주 묻는 질문</span>
          <div css={qnqSectionContainerCSS}>
            <QnaSection {...qna1} />
            <QnaSection {...qna2} />
            <QnaSection {...qna3} />
          </div>
        </div>
      </div>
      <div css={lowerImageContainerCSS}>
        <img src={bgLowerImg} alt={""} css={imageCSS} />
      </div>
      <div></div>
    </div>
  );
}
const imageCSS = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const lowerImageContainerCSS = css`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 352px;

  ${getWidthMediaQuery("pc")} {
    height: 704px;
  }
`;

const qnqSectionContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${getWidthMediaQuery("pc")} {
    gap: 30px;
    width: 980px;
  }
`;
const qnaContentContainerCSS = css`
  position: absolute;
  z-index: 1;
  top: 58px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  gap: 50px;

  ${getWidthMediaQuery("pc")} {
    gap: 60px;
    top: 204px;
  }
`;

const qnaTitleTextCSS = css`
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: ${fontWeight.Bold};
  line-height: 160%; /* 57.6px */
  letter-spacing: -1.8px;

  ${getWidthMediaQuery("pc")} {
    font-size: 100px;
  }
`;
const imgCSS = css`
  width: 100%;
  height: 650px;

  ${getWidthMediaQuery("pc")} {
    height: 1406px;
  }
`;

const qnaContainerCSS = css`
  position: relative;
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
`;

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";

export interface TraineeFeedbackProps {
  feedback: string;
}

export function TraineeFeedback({ feedback }: TraineeFeedbackProps) {
  return (
    <div css={containerCSS}>
      <div css={headerContainerCSS}>
        <span css={headerTextCSS}>한줄평</span>
        <span css={headerSubTextCSS}>트레이너님께 전달된 내용</span>
      </div>
      <div css={bodyCSS}>
        <span css={feedbackTextCSS}>{feedback}</span>
      </div>
    </div>
  );
}

const headerContainerCSS = css`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const feedbackTextCSS = css`
  color: ${colors.gray600};
  ${typography.mobile.body1}
`;

const bodyCSS = css`
  background: ${colors.lightGray};
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const headerTextCSS = css`
  color: ${colors.default.Gray};
  ${typography.mobile.heading2}
`;

const headerSubTextCSS = css`
  color: ${colors.gray700};
  ${typography.mobile.body2}
`;

const containerCSS = css`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

import {
  ComponentPropsWithRef,
  Fragment,
  HTMLAttributes,
  ReactElement,
  useContext,
} from "react";
import { Link as RouterLink } from "react-router-dom";

import * as TablePrimitive from "design-system/components/Table/Table";
import DataTableContext from "design-system/components/DataTable/dataTableContext";
import { colors } from "design-system/styles/colors";
import { css, Interpolation, Theme } from "@emotion/react";
import {
  ColumnDef,
  CoreRow,
  flexRender,
  getCoreRowModel,
  InitialTableState,
  Table as TableInterface,
  useReactTable,
} from "@tanstack/react-table";
import { typography } from "design-system/styles/typography/typography";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data?: TData[];
  table?: TableInterface<TData>;
  emptyContent?: ReactElement;
  initialState?: InitialTableState;
  isShowHeader?: boolean;
  onRowClick?: (original: CoreRow<TData>["original"]) => void;
}

export type { ColumnDef };

interface DataTableRootProps extends HTMLAttributes<HTMLDivElement> {
  styles?: Interpolation<Theme>;
  isResponsive?: boolean;
}

export function Root({
  styles,
  isResponsive = true,
  ...props
}: DataTableRootProps) {
  return (
    <DataTableContext.Provider
      value={{
        isResponsive,
      }}
    >
      <div css={[dataTableRootCSS, styles]} {...props} />
    </DataTableContext.Provider>
  );
}

export function Table<TData, TValue>({
  columns,
  data = [],
  table,
  emptyContent,
  initialState,
  isShowHeader = true,
  onRowClick,
}: DataTableProps<TData, TValue>) {
  const dataTableContext = useContext(DataTableContext);
  const defaultTable = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    initialState: {
      pagination: {
        pageSize: 20,
      },
    },
    ...initialState,
  });
  const dataTable = table || defaultTable;

  return (
    <TablePrimitive.Root
      styles={dataTableContext?.isResponsive && tableRootResponsiveCSS}
    >
      {!!data?.length && (
        <colgroup>
          {dataTable.getAllColumns().map((column) => {
            const size = column.columnDef.size;
            const width =
              size !== Number.MAX_SAFE_INTEGER && size ? size : "auto";

            return (
              <Fragment key={column.id}>
                <col
                  style={{
                    width,
                    minWidth: column.columnDef.minSize,
                    maxWidth: column.columnDef.maxSize,
                  }}
                />
              </Fragment>
            );
          })}
        </colgroup>
      )}
      <TablePrimitive.Header
        styles={dataTableContext?.isResponsive && tableHeaderResponsiveCSS}
      >
        {dataTable.getHeaderGroups().map((headerGroup) => (
          <TablePrimitive.Row
            styles={[
              // dataTableContext?.isResponsive && tableRowResponsiveCSS,
              // dataTableContext?.isResponsive && tableHeaderRowResponsiveCSS,
              css`
                cursor: pointer;

                :hover {
                  color: ${colors.gray200};
                }
              `,
            ]}
            key={headerGroup.id}
          >
            {isShowHeader &&
              headerGroup.headers.map((header) => {
                return (
                  <TablePrimitive.Head
                    key={header.id}
                    styles={
                      dataTableContext?.isResponsive && tableHeadResponsiveCSS
                    }
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TablePrimitive.Head>
                );
              })}
          </TablePrimitive.Row>
        ))}
      </TablePrimitive.Header>
      <TablePrimitive.Body
        styles={dataTableContext?.isResponsive && tableBodyResponsiveCSS}
      >
        {dataTable.getRowModel().rows?.length > 0 ? (
          dataTable.getRowModel().rows.map((row) => (
            <TablePrimitive.Row
              key={row.id}
              data-state={row.getIsSelected() && "selected"}
              onClick={() => onRowClick?.(row.original)}
              styles={[
                dataTableContext?.isResponsive && tableRowResponsiveCSS,
                dataTableContext?.isResponsive && tableBodyRowResponsiveCSS,
              ]}
            >
              {row.getVisibleCells().map((cell) => (
                <TablePrimitive.Cell
                  key={cell.id}
                  styles={[
                    dataTableContext?.isResponsive && tableCellResponsiveCSS,
                    dataTableContext?.isResponsive &&
                      tablePivotedCellResponsiveCSS,
                  ]}
                >
                  {isShowHeader &&
                    dataTableContext?.isResponsive &&
                    dataTable.getHeaderGroups().map((headerGroup) => (
                      <Fragment key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return cell.column.columnDef.header ===
                            header.column.columnDef.header ? (
                            <TablePrimitive.Head
                              key={header.id}
                              styles={
                                dataTableContext?.isResponsive &&
                                tableMobileHeadResponsiveCSS
                              }
                            ></TablePrimitive.Head>
                          ) : null;
                        })}
                      </Fragment>
                    ))}
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TablePrimitive.Cell>
              ))}
            </TablePrimitive.Row>
          ))
        ) : (
          <>
            {emptyContent && (
              <tr>
                <td
                  colSpan={columns.length}
                  css={dataTableEmptyContentWrapperCSS}
                >
                  {emptyContent}
                </td>
              </tr>
            )}
          </>
        )}
      </TablePrimitive.Body>
    </TablePrimitive.Root>
  );
}

interface DataTableLinkProps extends ComponentPropsWithRef<typeof RouterLink> {
  styles?: Interpolation<Theme>;
}

export function Link({ styles, ...props }: DataTableLinkProps) {
  return <RouterLink css={[dataTableLinkCSS, styles]} {...props} />;
}

// interface DataTableExternalLinkProps
//   extends ComponentPropsWithRef<typeof ExternalLinkPrimitive> {
//   styles?: Interpolation<Theme>
// }

// export function ExternalLink({ styles, ...props }: DataTableExternalLinkProps) {
//   return (
//     <ExternalLinkPrimitive
//       css={[dataTableExternalLinkCSS, styles]}
//       {...props}
//     />
//   )
// }

interface DataTableEmptyContentProps extends HTMLAttributes<HTMLDivElement> {
  styles?: Interpolation<Theme>;
}

export function EmptyContent({ styles, ...props }: DataTableEmptyContentProps) {
  return <div css={[dataTableEmptyContentCSS, styles]} {...props} />;
}

const dataTableRootCSS = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;

  gap: 40px;
`;

const dataTableLinkCSS = css`
  display: contents;

  :hover {
    color: ${colors.gray200};
  }
`;

const dataTableEmptyContentWrapperCSS = css`
  display: flex;
  width: 100%;
`;

const dataTableEmptyContentCSS = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 140px;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${typography.Button2};
  color: ${colors.default.Gray500};
`;

const tableRootResponsiveCSS = css`
  display: table;
`;

const tableHeaderResponsiveCSS = css`
  //display: block;
  display: table-header-group;
`;

const tableBodyResponsiveCSS = css`
  //display: flex;
  flex-direction: column;
  gap: 32px;
  display: table-row-group;
`;

const tableHeadResponsiveCSS = css`
  //display: block;
  display: table-cell;
`;

const tableRowResponsiveCSS = css`
  //display: block;

  :not(:first-of-type) {
    border-top: 2px solid ${colors.default.Black};
  }

  display: table-row;

  :not(:first-of-type) {
    border-top: 0;
  }
`;

const tableHeaderRowResponsiveCSS = css`
  //position: absolute;
  //top: -9999px;
  //left: -9999px;

  position: relative;
  top: unset;
  left: unset;
`;

const tableBodyRowResponsiveCSS = css`
  //border-bottom: 0;

  border-bottom: 1px solid ${colors.default.Black_a10};
`;

const tableCellResponsiveCSS = css`
  //display: flex;
  align-items: center;

  display: table-cell;
`;

const tablePivotedCellResponsiveCSS = css`
  //position: relative;
  //padding-left: 110px;
  //text-align: left;
  border-bottom: 1px solid ${colors.default.Black_a10};

  padding-left: 0;
  text-align: unset;
  border-bottom: 0;
`;

const tableMobileHeadResponsiveCSS = css`
  position: absolute;
  left: 0;
  //display: flex;
  align-items: center;
  text-align: left;
  //width: 110px;

  display: none;
`;

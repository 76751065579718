// colors.js
export const colors = {
  /* Primary Colors */
  green: "#e0f569",
  black: "#101010",

  /* Black */
  black400: "#660c0c0d",

  /* Primary Colors (Lime) */
  lime50: "#fbfeec",
  lime100: "#f5fccf",
  lime200: "#f1fbbc",
  lime300: "#eefaae",
  lime400: "#eaf89b",
  lime500: "#e5f783",
  lime600: "#e0f569", // Main color
  lime700: "#dbf453",
  lime800: "#d0f01a",
  lime900: "#c1e10f",

  /* Secondary Colors */
  orange: "#f24722",
  cobaltBlue: "#57cfc9",
  blue: "#2e9cf4",
  purple: "#692c7e",

  /* Grayscale */
  gray25: "#ffffff",
  gray50: "#f1f1f1",
  gray100: "#e5e5e5",
  gray200: "#cbcbcb",
  gray300: "#b2b2b2",
  gray400: "#989898",
  gray500: "#7f7f7f",
  gray600: "#656565",
  gray700: "#4c4c4c",
  gray800: "#323232",
  gray850: "#424242",
  gray900: "#191919",

  Black_a70: "rgba(28,30,34,0.7)",
  Black_a40: "rgba(28,30,34,0.4)",
  Black_a20: "rgba(28,30,34,0.2)",
  Black_a10: "rgba(28,30,34,0.1)",
  Black_a5: "rgba(28,30,34,0.05)",
  Black_a0: "rgba(28,30,34,0)",
  BlackDimmed: "rgba(0, 0, 0, 0.8)",
  White: "rgba(255,255,255,1)",
  White_a70: "rgba(255,255,255,0.7)",
  White_a40: "rgba(255,255,255,0.4)",
  White_a0: "rgba(255,255,255,0)",

  default: {
    Gray: "#333539",
    Gray100: "#494b4e",
    Gray200: "#616265",
    Gray300: "#77787a",
    Gray400: "#8d8e90",
    Gray500: "#a4a5a7",
    Gray600: "#bbbcbd",
    Gray700: "#d2d2d3",
    Gray800: "#e9e9e9",
    Gray900: "#f5f5f5",
    Black: "rgba(28,30,34,1)",
    Black_a70: "rgba(28,30,34,0.7)",
    Black_a40: "rgba(28,30,34,0.4)",
    Black_a20: "rgba(28,30,34,0.2)",
    Black_a10: "rgba(28,30,34,0.1)",
    Black_a5: "rgba(28,30,34,0.05)",
    Black_a0: "rgba(28,30,34,0)",
    BlackDimmed: "rgba(0, 0, 0, 0.8)",
    White: "rgba(255,255,255,1)",
    White_a70: "rgba(255,255,255,0.7)",
    White_a40: "rgba(255,255,255,0.4)",
    White_a0: "rgba(255,255,255,0)",
  },
  /* Background Color */
  lightGray: "#f8f8f8",

  /* Alpha */
  alpha10: "rgba(0, 0, 0, 0.1)",

  /* Kakao Yellow */
  kakaoYellow: "#fee500",
};

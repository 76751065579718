import { GetLessonsListRequest, GetLessonsListResponse } from "./lessonsTypes";
import { customFetch } from "../../../libs/fetch/fetch";

export async function getLessonList({
  filter,
}: GetLessonsListRequest): Promise<GetLessonsListResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/lessons/list`,
    params: { filter },
  });
}

import { customFetch } from "../../../libs/fetch/fetch";
import { GetMeResponse } from "./meTypes";

export async function getMe(): Promise<GetMeResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/me`,
  });
}

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import {
  WorkoutIntensity,
  workoutIntensityInKR,
} from "../../../../api/common/commonEnumType";
import React from "react";
import { IntensityGraph } from "design-system/components/IntensityGraph/IntensityGraph";

export interface IntensityEvaluationProps {
  date: Date;
  intensity: WorkoutIntensity;
}

export function IntensityEvaluation({
  intensity,
  date,
}: IntensityEvaluationProps) {
  return (
    <div css={containerCSS}>
      <span css={headerTextCSS}>운동강도 평가</span>
      <div css={bodyCSS}>
        <span css={titleTextCSS}>
          {date && <>{date.getFullYear()}년 </>}
          {date && <>{date.getMonth() + 1}월 </>}
          {date && <>{date.getDate()}일</>}의 PT는{" "}
          {workoutIntensityInKR(intensity)}
        </span>
        <IntensityGraph intensity={intensity} />
      </div>
    </div>
  );
}

const titleTextCSS = css`
  ${typography.mobile.heading3}
`;

const bodyCSS = css`
  background: ${colors.lightGray};
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const headerTextCSS = css`
  color: ${colors.default.Gray};
  ${typography.mobile.heading2}
`;

const containerCSS = css`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import Button from "design-system/components/Button/Button";
import { LoginDialog } from "../../common/login-dialog/LoginDialog";
import LoginDialogContext from "../../common/login-dialog/loginDialogContext";
import { EventName, ScreenName, useAmplitude } from "../../hoock/useAmplitude";

type StartVariant = "web" | "mobile" | "tablet";

export function StartPage() {
  const [isOpen, setIsOpen] = useState(false);
  const { sendClickEvent } = useAmplitude();
  const startWeb = () => {
    sendClickEvent({
      eventName: EventName.web_start_crm_button_clicked,
      screenName: ScreenName.web_home_main,
    });
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div css={tempContainerCSS}>
        {/*TODO 버튼 교체 필요*/}
        <Button variant={"primary"} size={40} onClick={startWeb}>
          Web으로 시작하기
        </Button>
      </div>
      <LoginDialogContext.Provider value={{ isOpen, setIsOpen }}>
        <LoginDialog />
      </LoginDialogContext.Provider>
    </>
  );
}

const tempContainerCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-content: center;
  width: 500px;
  height: 500px;
`;

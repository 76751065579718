import { css } from "@emotion/react";
import { getWidthMediaQuery } from "../../styles/mediaQueries";
import { fontWeight } from "design-system/styles/typography/typography";

export interface InfoCardProps {
  // cardTitle: string;
  imageSrc: string;
  title: string;
  body: string;
}

/** @jsxImportSource @emotion/react */
export default function InfoCard({ imageSrc, title, body }: InfoCardProps) {
  return (
    <div css={containerCSS}>
      <div css={infoCardContainerCSS}>
        <img css={imageCSS} src={imageSrc} alt={title} />
      </div>
      <div css={contentContainerCSS}>
        <span css={titleCSS}>{title}</span>
        <span css={subTitleCSS}>{body}</span>
      </div>
    </div>
  );
}

const infoCardContainerCSS = css`
  max-width: 400px;

  ${getWidthMediaQuery("pc")} {
    height: 383px;
    max-width: none;
  }

  ${getWidthMediaQuery("default")} {
    height: 287px;
  }
`;

const contentContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 10px;

  ${getWidthMediaQuery("pc")} {
    gap: 10px;
  }
`;
const subTitleCSS = css`
  color: #989898;
  font-size: 16px;
  font-weight: ${fontWeight.Medium};
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.8px;

  ${getWidthMediaQuery("pc")} {
    font-size: 30px;
    font-weight: ${fontWeight.Bold};
    letter-spacing: -1.5px;
  }

  ${getWidthMediaQuery("default")} {
    font-weight: ${fontWeight.Medium};
    line-height: 140%; /* 48px */
  }
`;

const titleCSS = css`
  color: #fff;
  font-size: 24px;
  font-weight: ${fontWeight.Bold};
  line-height: 160%; /* 38.4px */
  letter-spacing: -2.05px;

  ${getWidthMediaQuery("pc")} {
    font-size: 41px;
  }
`;

const imageCSS = css`
  object-fit: cover;
  width: 100%;
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  ${getWidthMediaQuery("pc")} {
    gap: 21px;
    width: 260px;
    padding-right: 0;
    padding-left: 0;
  }

  ${getWidthMediaQuery("default")} {
    gap: 21px;
    width: 398px;
  }
`;

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { ImageView } from "design-system/components/ImageView/ImageView";

export interface FeedbackProps {
  trainerName: string;
  trainerImg: string;
  feedback: string;
}

export function TotalFeedback({
  trainerName,
  trainerImg,
  feedback,
}: FeedbackProps) {
  return (
    <div css={containerCSS}>
      <span css={headerTextCSS}>종합 피드백</span>
      <div css={bodyCSS}>
        <div css={bodyContentUpperCSS}>
          <ImageView
            src={trainerImg}
            css={trainerImageCSS}
            variant={"circle"}
          />
          <span css={trainerNameTextCSS}>{trainerName}트레이너</span>
        </div>
        <span css={feedbackTextCSS}>{feedback}</span>
      </div>
    </div>
  );
}

const trainerImageCSS = css`
  width: 40px;
  height: 40px;
  border-radius: 50%; /* 원형으로 자르기 */
  object-fit: cover; /* 이미지를 잘리지 않고 요소 안에 맞추기 */
`;

const feedbackTextCSS = css`
  color: ${colors.gray600};
  ${typography.mobile.body1}
`;

const trainerNameTextCSS = css`
  ${typography.mobile.body1}
`;
const bodyContentUpperCSS = css`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const bodyCSS = css`
  background: ${colors.lightGray};
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const headerTextCSS = css`
  color: ${colors.default.Gray};
  ${typography.mobile.heading2}
`;

const containerCSS = css`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

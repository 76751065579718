import {fontFamily, fontWeight, typography,} from "design-system/styles/typography/typography";
import {css} from "@emotion/react";
import Natompro from "./fonts/natompro/Fontspring-DEMO-natompro-bold.otf";
import PretendardMedium from "./fonts/pretendard/Pretendard-Medium.subset.woff2";

import SuitBold from "../resource/fonts/suit/SUIT-Bold.woff2";
import SuitHeavy from "../resource/fonts/suit/SUIT-Heavy.woff2";
import SuitExtraBold from "../resource/fonts/suit/SUIT-ExtraBold.woff2";
import SuitSemiBold from "../resource/fonts/suit/SUIT-SemiBold.woff2";
import SuitMedium from "../resource/fonts/suit/SUIT-Medium.woff2";
import {colors} from "design-system/styles/colors";

/** @jsxImportSource @emotion/react */
export const globalStyles = css`
  @font-face {
    font-family: ${fontFamily.suit};
    font-weight: ${fontWeight.Bold};
    font-display: swap;
    src: url(${SuitBold}) format("woff2");
  }
  @font-face {
    font-family: ${fontFamily.suit};
    font-weight: ${fontWeight.Heavy};
    font-display: swap;
    src: url(${SuitHeavy}) format("woff2");
  }
  @font-face {
    font-family: ${fontFamily.suit};
    font-weight: ${fontWeight.ExtraBold};
    font-display: swap;
    src: url(${SuitExtraBold}) format("woff2");
  }
  @font-face {
    font-family: ${fontFamily.suit};
    font-weight: ${fontWeight.SemiBold};
    font-display: swap;
    src: url(${SuitSemiBold}) format("woff2");
  }

  @font-face {
    font-family: ${fontFamily.suit};
    font-weight: ${fontWeight.Medium};
    font-display: swap;
    src: url(${SuitMedium}) format("woff2");
  }

  @font-face {
    font-family: ${fontFamily.natomPro};
    font-weight: ${fontWeight.Bold};
    font-display: swap;
    src: url(${Natompro}) format("opentype");
  }

  @font-face {
    font-family: ${fontFamily.pretendard};
    font-weight: ${fontWeight.Medium};
    font-display: swap;
    src: url(${PretendardMedium}) format("wotf2");
  }

  * {
    font-family:
      ${fontFamily.suit},
      ${fontFamily.natomPro},
      sans-serif,
      -apple-system,
      BlinkMacSystemFont,
      system-ui,
      Roboto,
      "Helvetica Neue",
      "Segoe UI",
      "Noto Sans KR",
      "Malgun Gothic",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      sans-serif;
    box-sizing: border-box;
    touch-action: manipulation;
    // 모바일에서 cursor:pointer; 클릭시 파란 선택박스 나오는 설정 끄기
  }

  :not(html) > * {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    word-break: keep-all;
  }

  .App {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  menu,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section {
    display: block;
  }

  /* HTML5 hidden-attribute fix for newer browsers */

  *[hidden] {
    display: none;
  }

  body {
    line-height: 1;
    overflow-x: hidden;
  }

  menu,
  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  button {
    border: none;
  }

  input {
    user-select: text;
  }

  textarea,
  input {
    outline: none;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    ${typography.Body3}; // 기본폰트 설정
    line-height: normal;
    letter-spacing: normal;
    background-color: ${colors.gray25}; // https://groundx.atlassian.net/browse/KLQA-3668
  }

  html > body {
    overflow-y: scroll !important;
  }

  svg {
    path {
      color: currentColor;
    }
  }

  .container {
    height: 100vh;
  }

  #root {
    min-height: 100vh;
    height: auto;
  }
`;

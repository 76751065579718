import { ReportWrittenType } from "../../../../api/common/commonEnumType";
import React, { useContext } from "react";
import * as Tabs from "design-system/components/Tabs/Tabs";
import * as DataTable from "design-system/components/DataTable/DataTable";
import { useQuery } from "@tanstack/react-query";
import { getLessonList } from "../../../../api/trainer/lessons/lessons";
import { queryKeys } from "../../../../libs/react-query";
import SidebarContext from "../../../Sidebar/sidebarContext";
import { css } from "@emotion/react";
import { useNavigate } from "react-router";
import {
  EventName,
  ScreenName,
  useAmplitude,
} from "../../../../hoock/useAmplitude";

export function ListTabContent() {
  const { sidebarState, setSidebarState } = useContext(SidebarContext);
  const navigate = useNavigate();
  const { sendClickEvent } = useAmplitude();

  const { data: lessonListData } = useQuery({
    queryFn: () =>
      getLessonList({
        filter:
          sidebarState == "all"
            ? undefined
            : (sidebarState as ReportWrittenType),
      }),
    queryKey: queryKeys.getLessonsList({
      filter:
        sidebarState === "all" || sidebarState === "none"
          ? undefined
          : sidebarState,
    }),
    cacheTime: 0,
    staleTime: 0,
  });

  return (
    <div css={containerCSS}>
      <Tabs.Root
        onValueChange={(value) => {
          setSidebarState(value as ReportWrittenType);
        }}
        variant="default"
        defaultValue={"all"}
        value={sidebarState}
      >
        <Tabs.List>
          <Tabs.Trigger value={"all"} styles={tabTriggerCSS}>
            전체({lessonListData?.allCount})
          </Tabs.Trigger>
          <Tabs.Trigger value={"not_written"} styles={tabTriggerCSS}>
            미작성({lessonListData?.notWrittenCount})
          </Tabs.Trigger>
          <Tabs.Trigger value={"writing"} styles={tabTriggerCSS}>
            작성중({lessonListData?.writingCount})
          </Tabs.Trigger>
          <Tabs.Trigger value={"completed"} styles={tabTriggerCSS}>
            작성완료({lessonListData?.completedCount})
          </Tabs.Trigger>
        </Tabs.List>
      </Tabs.Root>
      <DataTable.Root>
        <DataTable.Table
          onRowClick={(original) => {
            sendClickEvent({
              eventName: EventName.web_report_item_button_clicked,
              screenName: ScreenName.web_pt_report_list,
              eventProperties: {
                report_type: original.status,
              },
            });
            if (original.status == "completed") {
              navigate(
                `/crm/report?userId=${original.info.traineeId}&lessonId=${original.info?.lessonId ?? 0}&scheduleSlotId=${original.info.scheduleSlotId}`,
              );
            } else {
              navigate(
                `/crm/report/create?type=${original.info.preferredLessonType ?? "feedback_workout_set_weight"}&userId=${original.info.traineeId}&lessonId=${original.info?.lessonId ?? 0}&ticketId=${original.info.ticketId}&scheduleSlotId=${original.info.scheduleSlotId}`,
              );
            }
          }}
          isShowHeader={!!lessonListData?.lessons?.length}
          emptyContent={
            <DataTable.EmptyContent>
              표시할 항목이 없어요
            </DataTable.EmptyContent>
          }
          data={lessonListData?.lessons || []}
          columns={[
            {
              cell: ({ row }) => row.original.info.scheduleSlotId,
              header: "No.",
              size: 120,
            },
            {
              cell: ({ row }) => {
                if (row.original.status == "not_written") {
                  return "미작성";
                } else if (row.original.status == "writing") {
                  return "작성중";
                } else {
                  return "작성완료";
                }
              },
              header: "상태",
              size: 150,
            },
            {
              cell: ({ row }) => row.original.info.traineeName,
              header: "회원명",
              size: 170,
            },
            {
              cell: ({ row }) => {
                const date = new Date(row.original.info.startAt);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                return `${year}.${month}.${day}`;
              },
              header: "날짜",
              size: 150,
            },
            {
              cell: ({ row }) => {
                const date = new Date(row.original.info.startAt);
                const hours = String(date.getHours()).padStart(2, "0"); // 두 자리 시간
                const minutes = String(date.getUTCMinutes()).padStart(2, "0"); // 두 자리 분
                return `${hours}:${minutes}`;
              },
              header: "시작시간",
              size: 150,
            },
            {
              cell: ({ row }) => {
                return `${row.original.info.orders} / ${row.original.info.totalCount}`;
              },
              header: "횟수",
              size: 150,
            },
            {
              cell: ({ row }) => {
                if (row.original.info.preferredLessonType == "feedback_only") {
                  return "간단일지";
                } else if (
                  row.original.info.preferredLessonType ==
                  "feedback_workout_set_weight"
                ) {
                  return "상세일지";
                } else {
                  return "기본일지";
                }
              },
              header: "선호일지 유형",
              minSize: 150,
            },
          ]}
        ></DataTable.Table>
      </DataTable.Root>
    </div>
  );
}

const containerCSS = css`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const tabTriggerCSS = css``;

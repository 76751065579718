import {
  QueryClient,
  QueryClientConfig,
  QueryKey,
  QueryObserverOptions,
  Updater,
} from "@tanstack/react-query";
import { GetSigninKakaoOauthTokenRequest } from "../api/trainer/signin/siginTypes";
import { GetLessonsListRequest } from "../api/trainer/lessons/lessonsTypes";
import {
  getUsersUserId,
  getUsersUserIdLessonsLessonId,
  getUsersUserIdWorkoutsWorkoutId,
} from "../api/trainer/users/users";
import {
  GetUsersUserIdLessonsLessonIdRequest,
  GetUsersUserIdWorkoutsWorkoutIdRequest,
} from "../api/trainer/users/usersTypes";
import { getSchedulesScheduleSlotIdUsersUserIdTicket } from "../api/trainer/schedules/schedules";
import { getSchedulesScheduleSlotIdUsersUserIdTicketRequest } from "../api/trainer/schedules/schedulesTypes";

export const queryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      suspense: true,
      onError: (err) => {
        throw err;
      },
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: true,
      staleTime: 30 * 1000,
      networkMode: "always",
    },
    mutations: {
      retry: false,
      useErrorBoundary: true,
    },
  },
};

export const queryClient = new QueryClient(queryClientOptions);

export const queryKeys = {
  getSigninKakaoOauthToken: ({ code }: GetSigninKakaoOauthTokenRequest) => [
    "getSigninKakaoOauthToken",
    code,
  ],
  getLessonsList: ({ filter }: GetLessonsListRequest) => [
    "getLessonsList",
    filter,
  ],
  getUsersUserIdLessonsLessonId: ({
    userId,
    lessonId,
  }: GetUsersUserIdLessonsLessonIdRequest) => [
    "getUsersUserIdLessonsLessonId",
    userId,
    lessonId,
  ],
  getUsersUserIdWorkoutsWorkoutId: ({
    userId,
    workoutId,
    isCustom,
  }: GetUsersUserIdWorkoutsWorkoutIdRequest) => [
    "getUsersUserIdWorkoutsWorkoutId",
    userId,
    workoutId,
    isCustom,
  ],
  getUsersUserId: (userId: number) => ["getUsersUserId"],
  getWorkouts: () => ["getWorkouts"],
  getMe: () => ["getMe"],
  getSchedulesScheduleSlotIdUsersUserIdTicket: ({
    userId,
    scheduleSlotId,
  }: getSchedulesScheduleSlotIdUsersUserIdTicketRequest) => [
    "getSchedulesScheduleSlotIdUsersUserIdTicket",
    userId,
    scheduleSlotId,
  ],
  setError: (props: { key: string }) => ["setError", { ...props }],
} as const;

export const setQueryData = <T>({
  queryKey,
  options,
  updater,
}: {
  queryKey: QueryKey;
  options: QueryObserverOptions;
  updater: Updater<T | undefined, T | undefined>;
}) => {
  queryClient.setQueryDefaults(queryKey, options);
  queryClient.setQueryData(queryKey, updater);
};

export const resetErrorQueries = () => {
  const queryCache = queryClient.getQueryCache();
  const queryCaches = queryCache.getAll();

  const errorQueries = queryCaches
    .filter(({ state }) => {
      return state.status !== "success";
    })
    .reduce((acc: unknown[], { queryKey }) => {
      return [...acc, ...queryKey];
    }, []);

  queryClient.resetQueries(errorQueries, { exact: true });
};

export const clearQueries = () => {
  queryClient.clear();
};

import Frame1 from "./frame1/Frame1";
import Frame2 from "./frame2/Frame2";
import flowImg from "../resource/trainee/belt.png";
import Frame3 from "./frame3/Frame3";
import { Frame4 } from "./Frame4/Frame4";
import Frame5 from "./frame5/Fram5";
import React from "react";
import { css } from "@emotion/react";
import { getWidthMediaQuery } from "../styles/mediaQueries";

/** @jsxImportSource @emotion/react */
export function TraineeApp() {
  return (
    <div css={mainContainerCSS}>
      <Frame1 />
      <Frame2 />
      <div css={flowTextContainer}>
        <img src={flowImg} alt={"FlowText"} css={imageCSS} />
        <img src={flowImg} alt={"FlowText"} css={imageCSS} />
      </div>
      <Frame3 />
      <Frame4 />
      <Frame5 />
    </div>
  );
}

const flowTextContainer = css`
  display: flex;
  background: #aaf89d;
`;
const imageCSS = css`
  height: 38px;

  ${getWidthMediaQuery("pc")} {
    height: 50px;
  }
`;

const mainContainerCSS = css`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;

  ${getWidthMediaQuery("pc")} {
    padding: 0;
  }
`;

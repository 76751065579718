export interface ErrorDataInterface {
  title?: string;
  message?: string;
  reason: string;
}

type ErrorObjectType = Record<number, ErrorDataInterface>;

// TODO : HTTP response status별 title, message 추가가 필요합니다. - @Matt
const NETWORK_ERROR_OBJECT: ErrorObjectType = {
  400: {
    title: "",
    message: "",
    reason: "BAD_REQUEST",
  },
  401: {
    title: "",
    message: "",
    reason: "UNAUTHORIZED",
  },
  403: {
    title: "",
    message: "",
    reason: "FORBIDDEN",
  },
  404: {
    title: "",
    message: "",
    reason: "NOT_FOUND",
  },
  409: {
    title: "",
    message: "",
    reason: "CONFLICT",
  },
  410: {
    title: "",
    message: "",
    reason: "GONE",
  },
  500: {
    title: "",
    message: "",
    reason: "INTERNAL_SERVER_ERROR",
  },
};

const CUSTOM_ERROR_OBJECT = {
  wrongQueryParameter: {
    title: "",
    message: "",
    reason: "WRONG_QUERY_PARAMETER",
  },
} as const;

export const ERROR_CODES = Object.keys(CUSTOM_ERROR_OBJECT).reduce<
  Record<keyof typeof CUSTOM_ERROR_OBJECT | string, string>
>((acc, curr) => {
  acc[curr] = curr;

  return acc;
}, {});

const mergedErrorObject = {
  ...CUSTOM_ERROR_OBJECT,
  ...NETWORK_ERROR_OBJECT,
};

export const ERROR_MAP = new Map<number | string, ErrorDataInterface>(
  Object.entries(mergedErrorObject),
);

export enum ErrorCode {
  SESSION = 400_006,
  NON_TOKEN = 400_004,
}

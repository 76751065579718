import { WorkoutDetailDTO } from "../../../../api/trainer/users/usersTypes";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { WorkoutItem } from "./WorkoutItem";

interface WorkoutContainerProps {
  workoutList: WorkoutDetailDTO[];
  userId: number;
}

export function WorkoutContainer({
  workoutList,
  userId,
}: WorkoutContainerProps) {
  return (
    <div css={containerCSS}>
      <span css={headerTextCSS}>PT 일지</span>
      <div css={listContainerCSS}>
        {workoutList.map((it, index) => (
          <WorkoutItem
            key={it.id}
            item={it}
            order={index + 1}
            userId={userId}
          />
        ))}
      </div>
    </div>
  );
}

const listContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const headerTextCSS = css`
  ${typography.mobile.heading2}
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: auto;
`;

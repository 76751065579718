import packageJson from "../../../package.json";
import Cookies from "js-cookie";

const protocol = process.env.REACT_APP_API_PROTOCOL;
const host = process.env.REACT_APP_API_HOST;
const ENV = process.env.REACT_APP_ENV;

type App = "user" | "trainer" | "common";
type ApiVersion = "v1" | "v2";
type SearchParamsType = Record<string, string | number | undefined | boolean>;

type RequestURLConfigType =
  | {
      app: App;
      version: ApiVersion;
      path: string;
      params?: SearchParamsType;
    }
  | { url: string };

export const customFetch = async (
  requestURLConfig: RequestURLConfigType,
  init?: RequestInit,
  url?: string,
) => {
  const isExternalUrl = "url" in requestURLConfig;
  const accessToken = Cookies.get("AT");
  const requestURL = getRequestURL(requestURLConfig);

  let response = await fetch(url ? url : requestURL, {
    credentials: "include",
    ...init,
    headers: {
      ...init?.headers,
      ...(!isExternalUrl && { "Client-Info": getClientHeader() }),
    },
  });

  response = await [...responseInterceptorMap.values()].reduce(
    async (prevResponse, interceptor) => interceptor(await prevResponse),
    Promise.resolve(response),
  );

  if (!response.ok) {
    throw {
      status: response.status,
      statusText: response.statusText,
      data: await returnResponse(response),
    };
  }

  return returnResponse(response);
};

export const returnResponse = async (response: Response) => {
  try {
    return await response.json();
  } catch {
    return response;
  }
};

export function useInterceptor() {
  return {
    addInterceptor: (
      callback: (response: Response) => Response | Promise<Response>,
    ) => {
      const uuid = window.crypto.randomUUID?.();

      responseInterceptorMap.set(uuid, callback);

      return uuid;
    },
    deleteInterceptor: (id: string) => responseInterceptorMap.delete(id),
  };
}

const getSearch = (params: SearchParamsType) => {
  const falsyRemovedObject = Object.keys(params).reduce(
    (acc: { [key: string]: string }, key) => {
      const value = params[key];

      if (value) {
        acc[key] = value.toString();
      }

      return acc;
    },
    {},
  );

  const searchParams = new URLSearchParams(falsyRemovedObject).toString();

  return searchParams ? `?${searchParams}` : "";
};

const getRequestURL = (configs: RequestURLConfigType) => {
  if ("url" in configs) {
    return configs.url;
  }

  const { version, path, params, app } = configs;

  if (!(protocol && host && version && path && app)) {
    throw new Error();
  }

  return `${protocol}://${host}/${app}/${version}${path}${getSearch(params || {})}`;
};

function getClientHeader() {
  const userAgent = navigator.userAgent;
  const regExp = /\((\w+).*?(?=\) )/g;
  const osInfoString = userAgent
    .match(regExp)?.[0]
    ?.replaceAll("(", "")
    .replaceAll("_", ".");

  if (!osInfoString) return "";

  const os = osInfoString.includes("iPhone")
    ? "ios"
    : osInfoString.includes("Android")
      ? "android"
      : osInfoString.includes("Windows")
        ? "windows"
        : "mac";

  const osVersionRegExp =
    os === "ios"
      ? /iPhone OS (\S+)/
      : os === "android"
        ? /Android (\S+)/
        : os === "windows"
          ? /Windows (\S+)/
          : /Macintosh (\S+)/;

  const osVersion =
    (os === "mac"
      ? osInfoString.split(" ")?.[5]
      : os === "windows"
        ? osInfoString.split(" ")?.[2]
        : osInfoString.match(osVersionRegExp)?.[1]
    )?.replace(";", "") ?? "";

  return JSON.stringify({
    os,
    ["os-version"]: osVersion,
    ["fitsyou-version"]: packageJson.version,
  });
}

export interface FieldError {
  field: string;
  message: string;
}

export interface CustomError {
  type: string;
  title: string;
  status: number;
  detail: string;
  code: number;
  err: string;
  errors?: FieldError[];
  role?: "host" | "subHost";
  nicknames?: string[];
}

export interface CustomFetchError extends Error {
  status: number;
  statusText: string;
  data?: CustomError;
}

export const responseInterceptorMap: Map<
  string,
  (response: Response) => Response | Promise<Response>
> = new Map();

import { createContext, Dispatch, SetStateAction } from "react";
import { SidebarState } from "./Sidebar";

interface SidebarContext {
  sidebarState: SidebarState;
  setSidebarState: Dispatch<SetStateAction<SidebarState>>;
}

export default createContext<SidebarContext>({
  sidebarState: "none",
  setSidebarState: () => {},
});

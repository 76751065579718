/** @jsxImportSource @emotion/react */

import * as DialogPrimitive from "@radix-ui/react-dialog";
import DialogContext from "./dialogContext";
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useContext,
} from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { fadeIn, fadeOut } from "../../styles/keyframes";
import { colors } from "../../styles/colors";

type DialogVariant = "alert" | "page" | "default";

export type DialogRootProps = DialogPrimitive.DialogProps & {
  variant?: DialogVariant;
  isAlertDialog?: boolean;
  isBorderRadius?: boolean;
};

function Root({
  variant = "alert",
  isAlertDialog,
  children,
  isBorderRadius = true,
  ...props
}: DialogRootProps) {
  return (
    <DialogContext.Provider
      value={{
        variant,
        isAlertDialog,
        isBorderRadius,
      }}
    >
      <DialogPrimitive.Root {...props}>
        <DialogOverlay />
        {children}
      </DialogPrimitive.Root>
    </DialogContext.Provider>
  );
}

const Content = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    styles?: Interpolation<Theme>;
  }
>(({ children, styles, onPointerDownOutside, ...props }, ref) => {
  const dialogContext = useContext(DialogContext);

  return (
    <DialogPortal>
      <DialogPrimitive.Content
        ref={ref}
        css={[
          dialogContentCSS,
          dialogContext?.variant === "page" && sizePage,
          // dialogContext?.variant === "alert" && sizeAlert,
          // dialogContext?.isBorderRadius ? borderRadius : noBorderRadius,
          styles,
        ]}
        onPointerDownOutside={
          dialogContext?.isAlertDialog
            ? (event) => event.preventDefault()
            : onPointerDownOutside
        }
        {...props}
      >
        {children}
      </DialogPrimitive.Content>
    </DialogPortal>
  );
});

const sizePage = css`
  width: 450px;
`;

const dialogContentCSS = css`
  position: fixed;
  z-index: 50;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  min-width: 312px;
  max-width: calc(100% - 48px);
  background-color: ${colors.gray25};
  border-radius: 16px;
  opacity: 0;
  overflow: hidden;

  &[data-state="open"] {
    animation: ${fadeIn} 0.35s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }

  &[data-state="closed"] {
    animation: ${fadeOut} 0.35s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
`;
const DialogPortal = ({ ...props }: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal {...props} />
);

const Trigger = DialogPrimitive.Trigger;

export { Root, Trigger, Content };

const DialogOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    css={[dialogOverlayCSS, styles]}
    {...props}
  />
));

DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const dialogOverlayCSS = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 50;
  background-color: rgba(28, 30, 34, 0.85);

  &[data-state="open"] {
    animation: ${fadeIn} 0.35s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }

  &[data-state="closed"] {
    animation: ${fadeOut} 0.35s cubic-bezier(0.65, 0, 0.35, 1) forwards;
  }
`;

import { useLocation, useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { getSignInKakaoOauthToken } from "./api/trainer/signin/siginin";
import { queryKeys } from "./libs/react-query";
import { useEffect } from "react";
import { getMe } from "./api/trainer/me/me";
import { useAmplitude } from "./hoock/useAmplitude";

export function Auth() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const authorizationCode = searchParams.get("code");
  const navigate = useNavigate();
  const { identifyUser, setUserId } = useAmplitude();

  const { data: token } = useQuery({
    queryFn: () =>
      getSignInKakaoOauthToken({
        code: authorizationCode!,
      }),
    queryKey: queryKeys.getSigninKakaoOauthToken({
      code: authorizationCode || "",
    }),
    enabled: !!authorizationCode,
  });

  const { data, isFetched } = useQuery({
    queryFn: () => getMe(),
    queryKey: queryKeys.getMe(),
    enabled: !!token,
  });

  useEffect(() => {
    if (data) {
      setUserId(data.email);
      identifyUser({
        name: data.name,
        birthDate: data.birthdate,
        phone: data.phone,
        gender: data.gender,
        email: data.email,
        yearsOfExperience: data.yearsOfExperience,
        isVerified: data.isVerified,
      });
    }
  }, [data]);

  useEffect(() => {
    if (isFetched && data?.isVerified !== true) {
      navigate("/error", {
        state: {
          title: "회원가입이 완료되지 않은 트레이너에요",
          subTitle:
            "'핏츠유 트레이너' 모바일 앱을 통해 회원가입후 이용해주세요",
        },
      });
    } else if (token && data) {
      navigate("/crm");
    }
  }, []);

  return <></>;
}

import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";
import { useContext } from "react";
import CreateContext from "../../createContext";
import { WorkoutItem } from "./WorkoutItem";

export function WorkoutList() {
  const { data, setData } = useContext(CreateContext);
  return (
    <div css={workoutListContainerCSS}>
      <div css={titleCSS}>PT일지</div>
      {data?.mixedWorkoutDetail?.map((detail, index) => {
        return (
          <WorkoutItem
              key={index}
            item={detail}
            order={index + 1}
            eraseWorkoutItem={() => {
              setData((prevState) => {
                const updatedWorkoutDetail = [
                  ...(prevState?.mixedWorkoutDetail || []),
                ]; // 배열 복사
                updatedWorkoutDetail.splice(index, 1); // index에 해당하는 항목 삭제

                return {
                  ...prevState,
                  mixedWorkoutDetail: updatedWorkoutDetail, // 삭제된 배열로 업데이트
                };
              });
            }}
            editWorkoutItem={(updatedWorkout) => {
              setData((prevState) => ({
                ...prevState,
                mixedWorkoutDetail: prevState?.mixedWorkoutDetail?.map(
                  (prevItem, preIndex) =>
                    preIndex === index // preIndex는 배열에서 현재의 인덱스, index는 상위 컴포넌트에서 전달된 인덱스
                      ? {
                          ...prevItem,
                          ...updatedWorkout, // 새로운 workout 값으로 업데이트
                        }
                      : prevItem, // 다른 항목들은 그대로 유지
                ),
              }));
            }}
          />
        );
      })}
    </div>
  );
}

const workoutListContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const titleCSS = css`
  ${typography.mobile.heading2}
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

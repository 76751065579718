import {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { css, Interpolation, Theme } from "@emotion/react";

const Input = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    styles?: Interpolation<Theme>;
    isError?: boolean;
    isValidated?: boolean;
    isLoading?: boolean;
    prefixContent?: ReactNode;
    suffixContent?: ReactNode;
    isShowCount?: boolean;
    maxLengthCount?: number;
    onChangeProps?: React.ChangeEventHandler<HTMLInputElement>;
  }
>(
  (
    {
      value,
      disabled,
      styles,
      isError,
      isValidated,
      isLoading,
      prefixContent,
      suffixContent,
      isShowCount,
      maxLength,
      maxLengthCount,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null); // input 요소 참조
    const [inputHeight, setInputHeight] = useState("auto"); // 높이 상태 관리

    // ref 연결 함수
    const setRefs = (el: HTMLInputElement | null) => {
      inputRef.current = el;
      if (typeof ref === "function") {
        ref(el);
      } else if (ref) {
        (ref as React.MutableRefObject<HTMLInputElement | null>).current = el;
      }
    };

    useEffect(() => {
      // 입력값이 변경될 때마다 높이 업데이트
      if (inputRef.current) {
        setInputHeight(`${inputRef.current.scrollHeight}px`);
      }
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (inputRef.current) {
        // 입력값에 따라 높이 조절
        setInputHeight("auto"); // 높이를 초기화한 후
        setInputHeight(`${inputRef.current.scrollHeight}px`); // 새로운 scrollHeight로 설정
      }

      if (props.onChange) {
        props.onChange(e); // 외부에서 전달된 onChange 호출
      }
    };

    return (
      <label
        css={[inputWrapperCSS, styles]}
        data-disabled={disabled}
        data-state={isError && "error"}
      >
        {prefixContent}
        <input
          css={[inputCSS, { height: inputHeight }]}
          value={value}
          // maxLength={maxLength}
          onChange={(event) => {
            if (typeof props.onChangeProps === "function") {
              props.onChangeProps(event);
            }
            handleChange(event); // 내부에서 처리할 onChange 함수
          }}
          ref={setRefs}
          {...props}
        />
        {suffixContent}
        {isShowCount && (maxLength || maxLengthCount) && (
          <span css={innerContentCSS}>
            {value?.toString().length || 0}/{maxLength || maxLengthCount}
          </span>
        )}
        {/*{isValidated && !isError && !isLoading && (*/}
        {/*  <span css={iconWrapperCSS}>*/}
        {/*    <PoDone css={iconCSS} />*/}
        {/*  </span>*/}
        {/*)}*/}
        {/*{isLoading && !isError && (*/}
        {/*  <span css={iconWrapperCSS}>*/}
        {/*    <Lottie json={pfLoadingBlue} styles={loadingLottieCSS} />*/}
        {/*  </span>*/}
        {/*)}*/}
      </label>
    );
  },
);

Input.displayName = "Input";

export { Input };

const inputWrapperCSS = css`
  display: flex;
  align-items: center;
  padding: 11px 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${colors.default.Gray600};
  background-color: transparent;
  gap: 16px;

  :focus-within {
    border: 1px solid ${colors.gray700};
    color: ${colors.default.Gray};
  }

  &[data-disabled="true"] {
    cursor: not-allowed;
    border: 1px solid ${colors.default.Gray800};
  }
`;

const inputCSS = css`
  display: flex;
  width: 100%;
  border: 0;
  padding: 0;
  background-color: ${colors.default.White};
  color: ${colors.default.Gray};
  //text-overflow: ellipsis;
  ${typography.Button2};

  :disabled {
    cursor: not-allowed;
    color: ${colors.default.Gray700};

    ::placeholder {
      color: ${colors.default.Gray700};
    }
  }

  ::placeholder {
    color: ${colors.default.Gray300};
  }
`;

const innerContentCSS = css`
  min-width: fit-content;
  color: ${colors.default.Gray300};
  ${typography.Button2};

  :disabled {
    cursor: not-allowed;
    color: ${colors.default.Gray700};
  }
`;

const iconWrapperCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
`;

const loadingLottieCSS = css`
  width: 24px;
  height: 24px;
`;

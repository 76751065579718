import {css} from "@emotion/react";
import {fontWeight} from "./design-system/styles/typography/typography";
import {useWindowSize} from "./hoock/useWindowSize";
import {getWidthMediaQuery} from "./styles/mediaQueries";

/** @jsxImportSource @emotion/react */
export function MainTitle() {
  const { isMobile } = useWindowSize();
  return (
    <div css={containerCSS}>
      <span css={subTitleCSS}>
        PT는
        {isMobile ? <br /> : " "}
        <span
          css={css`
            color: white;
          `}
        >
          트레이너 찾기
        </span>
        로 결정된다
      </span>
      <span css={titleCSS}>
        나에게 딱 맞는 <br />
        PT쌤을 만나는 곳
      </span>
    </div>
  );
}

const titleCSS = css`
  text-align: center;
  font-size: 57px;
  font-weight: ${fontWeight.Heavy};
  line-height: 120%; /* 178.8px */
  letter-spacing: -2.85px;
  background-clip: text;
  background: linear-gradient(109deg, #83f9e9 -2.12%, #e0f569 111.08%);
  -webkit-background-clip: text;
  color: transparent;

  ${getWidthMediaQuery("pc")} {
    font-size: 7.13vw;
    letter-spacing: -7.45px;
  }
`;

const subTitleCSS = css`
  color: #757575;
  font-size: 24px;
  text-align: center;
  font-weight: ${fontWeight.SemiBold};
  line-height: 160%; /* 62.4px */
  letter-spacing: -1.2px;

  ${getWidthMediaQuery("pc")} {
    font-size: 2vw;
    letter-spacing: -1.95px;
  }
`;

//TODO 마진 탑값 수정 예정
const containerCSS = css`
  align-items: center;
  margin-top: 143px;
  display: flex;
  flex-direction: column;
  gap: 43px;

  ${getWidthMediaQuery("pc")} {
    gap: 20px;
    margin-top: 7.23vw;
  }
`;

import { SVGProps } from 'react'

export default function PoDone({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      fill="currentColor"
      viewBox="0 0 72 72"
      {...props}
    >
      <path d="M28.35 52.65c-.3 0-.575-.05-.825-.15-.25-.1-.5-.275-.75-.525L13.2 38.4c-.45-.45-.675-1-.675-1.65 0-.65.225-1.2.675-1.65.45-.45.975-.675 1.575-.675s1.125.225 1.575.675l12 12 27.225-27.225a2.2 2.2 0 0 1 1.612-.675 2.2 2.2 0 0 1 1.613.675c.45.45.675.988.675 1.613A2.2 2.2 0 0 1 58.8 23.1L29.925 51.975c-.25.25-.5.425-.75.525-.25.1-.525.15-.825.15Z" />
    </svg>
  )
}

export type ReportType =
  | "feedback_only"
  | "feedback_workout"
  | "feedback_workout_set_weight";

export type ReportWrittenType = "not_written" | "writing" | "completed";

export type WorkoutParts =
  | "lower_body"
  | "chest"
  | "back"
  | "shoulders"
  | "arms"
  | "abs"
  | "etc"
  | "custom";

export type WorkoutIntensity =
  | "very_easy"
  | "easy"
  | "normal"
  | "hard"
  | "very_hard";

export type Gender = "male" | "female" | "else";

export type YearsOfExperience =
  | "one_to_two"
  | "three_to_five"
  | "six_to_ten"
  | "ten_over";

export const reportTypeInKR = (type: ReportType) => {
  if (type == "feedback_only") {
    return "간단 일지";
  } else if (type == "feedback_workout") {
    return "기본 일지";
  } else return "상세 일지";
};

export const workoutPartsInKR = (parts: WorkoutParts) => {
  if (parts == "lower_body") {
    return "하체";
  } else if (parts == "chest") {
    return "가슴";
  } else if (parts == "back") {
    return "등";
  } else if (parts == "shoulders") {
    return "어깨";
  } else if (parts == "arms") {
    return "팔";
  } else if (parts == "abs") {
    return "복근";
  } else {
    return "가타";
  }
};

export const workoutIntensityInKR = (intensity: WorkoutIntensity) => {
  if (intensity == "very_easy") {
    return "아주 쉬웠어요";
  } else if (intensity == "very_hard") {
    return "아주 힘들었어요";
  } else if (intensity == "hard") {
    return "힘들었어요";
  } else if (intensity == "easy") {
    return "쉬웠어요";
  } else {
    return "적당했어요";
  }
};

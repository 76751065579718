import React from "react";
import {css} from "@emotion/react";
import {getWidthMediaQuery} from "./styles/mediaQueries";
import {fontWeight} from "./design-system/styles/typography/typography";

/** @jsxImportSource @emotion/react */
export function ApplyButton() {
  return (
    <button
      css={buttonCSS}
      type="button"
      onClick={() => {
        window.open("https://open.kakao.com/o/sSqLYprg", "_blank");
      }}
    >
      <span css={buttonTextCSS}>40초만에 무료로 추천받기</span>
    </button>
  );
}

const buttonCSS = css`
  cursor: pointer;
  background-color: white;
  padding: 16px 28px;
  z-index: 1;
  border-radius: 1000px;
  align-self: center;
  display: flex;
  margin-top: 150px;

  ${getWidthMediaQuery("pc")} {
    margin-top: 3.12vw;
    padding: 26px 70px;
  }
`;

const buttonTextCSS = css`
  background: linear-gradient(91deg, #85e053 1.16%, #547fee 103.96%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  font-style: normal;
  letter-spacing: -1.6px;
  font-weight: ${fontWeight.Bold};
  line-height: 160%;

  ${getWidthMediaQuery("pc")} {
    font-size: 32px;
  }
`;

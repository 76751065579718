import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";

interface CrmTitleProps {
  title: string;
}

export function CrmTitle({ title }: CrmTitleProps) {
  return <span css={CrmTitleContainerTextCSS}>{title}</span>;
}

const CrmTitleContainerTextCSS = css`
  ${typography.Body2}
`;

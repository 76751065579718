import { css } from "@emotion/react";
import { useSearchParams } from "react-router-dom";
import { colors } from "design-system/styles/colors";
import React, { useEffect, useState } from "react";
import { CrmHeader } from "../../../common/Header/CrmHeader";
import { Sidebar } from "../../Sidebar/Sidebar";
import {
  CrmContentHorizontalPadding,
  CrmContentVerticalPadding,
} from "../../component/crmCSS";
import { ReportTypeCheck } from "./component/RoportTypeCheck/ReportTypeCheck";
import {
  ReportType,
  reportTypeInKR,
  WorkoutParts,
} from "../../../api/common/commonEnumType";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUsersUserIdLessonsLessonId } from "../../../api/trainer/users/users";
import { queryClient, queryKeys } from "../../../libs/react-query";
import CreateContext from "./createContext";
import {
  MixedWorkoutDetail,
  RequestSaveLessonRequestDTO,
  workoutDetailTORequestSaveLessonRequestWorkoutDetailDTO,
} from "../../../api/trainer/schedules/schedulesTypes";
import { Separator } from "./component/Separator/Separator";
import { WorkoutTime } from "./component/WorkoutTime/WorkoutTime";
import { WorkoutList } from "./component/Workout/WorkoutList";
import Button from "design-system/components/Button/Button";
import * as Dialog from "design-system/components/Dialog/Dialog";
import plusIcon from "resource/common/icon/ic_plus.svg";
import { TotalFeedback } from "./component/TotalFeecback/TotalFeedback";
import closeIcon from "resource/common/icon/ic_close.svg";
import { getWorkouts, postUploadsPresigned } from "../../../api/common/common";
import {
  AttachFileDTO,
  mapPartToKey,
  PostUploadsPresignedRequest,
  WorkoutCategoriesDetailDTO,
} from "../../../api/common/commonTypes";
import { typography } from "design-system/styles/typography/typography";
import {
  postSchedulesScheduleSlotIdLessons,
  putSchedulesScheduleSlotIdLessons,
} from "../../../api/trainer/schedules/schedules";
import { useNavigate } from "react-router";
import { customFetch } from "../../../libs/fetch/fetch";
import { extractVideoThumbnailFile } from "../../../hoock/useUrlToFile";
import Loading from "design-system/components/Loading/Loading";
import { AddCustomWorkoutDialog } from "./component/AddCustomWorkoutDialog/AddCustomWorkoutDialog";
import { SendErrorDialog } from "./component/SendErrorDialog/SendErrorDialog";
import customImage from "design-system/styles/assets/images/customImage.svg";
import { Textarea } from "design-system/components/Textarea/Textarea";
import * as RadioGroup from "design-system/components/RadioGroup/RadioGroup";
import { ReportShareDialog } from "../component/ReportShareDialog/ReportShareDialog";
import {
  EventName,
  ScreenName,
  useAmplitude,
} from "../../../hoock/useAmplitude";
import { ListCheckBox } from "design-system/components/Checkbox/ListCheckBox";

export function CrmReportWritePage() {
  const [searchParams] = useSearchParams();
  const [lessonId, setLessonId] = useState(
    parseInt(searchParams.get("lessonId") ?? "0", 10),
  );
  const userId = parseInt(searchParams.get("userId") ?? "0", 10);
  const ticketId = parseInt(searchParams.get("ticketId") ?? "0", 10);
  const [selectedPart, setSelectedPart] = useState<WorkoutParts | "total">(
    "lower_body",
  );
  const [findingPart, setFindingPart] = useState("");
  const [isNoSetError, setIsNoSetError] = useState(false);
  const [isNoWorkoutError, setIsNoWorkoutError] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [isRequireDataMissingError, setIsRequireDataMissingError] =
    useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { sendClickEvent } = useAmplitude();
  const preferredType =
    ((searchParams.get("type") ||
      "feedback_workout_set_weight") as ReportType) ||
    "feedback_workout_set_weight";

  const scheduleSlotId = parseInt(
    searchParams.get("scheduleSlotId") || "0",
    10,
  );
  const navigate = useNavigate();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isAddCustomDialogOpen, setIsAddCustomDialogOpen] = useState(false);

  const [addList, setAddList] = useState<WorkoutItem[]>([]);
  const [eraseList, setEraseList] = useState<WorkoutItem[]>([]);

  const { data: lessonData, refetch } = useQuery({
    queryFn: () =>
      getUsersUserIdLessonsLessonId({
        lessonId,
        userId,
      }),
    queryKey: queryKeys.getUsersUserIdLessonsLessonId({
      lessonId,
      userId,
    }),
    enabled: lessonId !== 0,
  });

  const { mutate: sendReportMutate, isLoading: isSending } = useMutation({
    mutationFn: postSchedulesScheduleSlotIdLessons,
    onSuccess: async ({ lessonId: responseLessonId }) => {
      queryClient.invalidateQueries(queryKeys.getLessonsList({}));
      sendLog(EventName.web_send_pt_diary_successed);
      // `lessonData`를 다시 가져오기
      setLessonId(responseLessonId);
      setIsShareDialogOpen(true);
    },
  });

  const { mutate: saveTempReportMutate, isLoading: isSaving } = useMutation({
    mutationFn: putSchedulesScheduleSlotIdLessons,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getLessonsList({}));
      queryClient.invalidateQueries(
        queryKeys.getUsersUserIdLessonsLessonId({ userId, lessonId }),
      );
      navigate("/crm");
    },
  });

  const sendLog = (eventName: EventName) => {
    sendClickEvent({
      eventName: eventName,
      screenName: ScreenName.web_pt_report_write,
      eventProperties: {
        feedback_type: reportData.lessonResultType,
        image_feedback_count:
          reportData.mixedWorkoutDetail?.reduce(
            (acc, item) => {
              const imageCount = item?.imagesDetail?.length ?? 0;
              return (parseInt(acc) + imageCount).toString();
            },
            "0", // 초기값을 문자열로 설정
          ) || "0",
        video_feedback_count:
          reportData.mixedWorkoutDetail?.reduce(
            (acc, item) => {
              const videoCount = item?.videosDetail?.length ?? 0;
              return (parseInt(acc) + videoCount).toString();
            },
            "0", // 초기값을 문자열로 설정
          ) || "0",
        is_prefer_feedback_matched:
          reportData.lessonResultType == lessonData?.lessonResultType
            ? "true"
            : "false",
      },
    });
  };

  const { mutateAsync: preSigned } = useMutation<
    AttachFileDTO,
    Error,
    PostUploadsPresignedRequest
  >({
    mutationFn: postUploadsPresigned,
  });

  const resourceUpload = async () => {
    for (const workoutsDetail of reportData.mixedWorkoutDetail || []) {
      for (const image of workoutsDetail.imagesDetail) {
        if (!image.id.includes("placeholder-")) continue;

        try {
          const response: AttachFileDTO = await preSigned({
            type: "user_workout_image",
            contentType: "png",
          });

          await customFetch(
            {
              app: "common",
              version: "v1",
              path: `/uploads/presigned`,
            },
            {
              method: "PUT",
              headers: {
                "Content-Type": "image/png",
              },
              body: image.file, // 파일 객체를 body로 직접 전달
            },
            response.url,
          );

          image.id = response.id;
        } catch (error) {
          console.error("Pre-signed URL 요청 실패:", error);
        }
      }

      for (const video of workoutsDetail.videosDetail) {
        if (!video.id.includes("placeholder-")) continue;

        try {
          const response = await preSigned({
            type: "user_workout_video",
            contentType: video.file?.type.includes("mp4") ? "mp4" : "mov",
            thumbnailContentType: "png",
          });
          console.log("t1", video.id);

          await fetch(response.url, {
            method: "PUT",
            headers: {
              "Content-Type": video.file?.type ?? "video/mp4",
            },
            body: video.file, // 파일 객체를 body로 직접 전달
          });

          if (response.thumbnail && video.file) {
            const thumbnailFile = await extractVideoThumbnailFile(video.file);
            console.log("t3", video.id);
            await fetch(response.thumbnail, {
              method: "PUT",
              headers: {
                "Content-Type": "image/png",
              },
              body: thumbnailFile, // 파일 객체를 body로 직접 전달
            });
          }
          console.log("t7", video.id);
          video.id = response.id;
          console.log("t8", video.id);
        } catch (error) {
          console.error("Pre-signed URL 요청 실패:", error);
        }
      }
    }
  };
  const getReportResponse = () => {
    console.log("ttt" + reportData.mixedWorkoutDetail?.toString());
    reportData.mixedWorkoutDetail?.map((item) => console.log(item));

    return {
      scheduleSlotId,
      ...reportData,
      workoutDetails: reportData.mixedWorkoutDetail?.map((detail) => ({
        id: detail.id,
        isCustom: detail.isCustom,
        workoutName: detail.workoutName,
        part: detail.part,
        sets: detail.sets,
        images: detail.imagesDetail.map((image) => image.id), // imagesDetail의 url 변환
        videos: detail.videosDetail.map((video) => video.id), // videosDetail의 url 변환
        feedback: detail.feedback,
      })),
    };
  };

  const saveTempReport = async () => {
    sendLog(EventName.web_temporary_save_pt_diary_button_clicked);
    setIsProcessing(true);
    await resourceUpload();
    saveTempReportMutate(getReportResponse());
    setIsProcessing(false);
  };

  const sendReport = async () => {
    sendLog(EventName.web_send_pt_diary_button_clicked);
    if (checkError()) {
      return;
    }
    console.log(1);
    setIsProcessing(true);
    console.log(2);
    await resourceUpload();
    console.log(3);
    sendReportMutate(getReportResponse());
    setIsProcessing(false);
  };

  const checkError = () => {
    if (
      reportData.totalFeedback?.length == 0 ||
      reportData.totalFeedback == undefined ||
      reportData.workoutTime == undefined
    ) {
      setIsRequireDataMissingError(true);
      return true;
    }

    if (reportData.lessonResultType !== "feedback_only") {
      if (
        !reportData.mixedWorkoutDetail ||
        reportData.mixedWorkoutDetail?.length == 0
      ) {
        setIsNoWorkoutError(true);
        return true;
      }
    }

    if (reportData.lessonResultType == "feedback_workout_set_weight") {
      const hasEmptySets = reportData.mixedWorkoutDetail?.some(
        (detail) => detail.sets.length === 0,
      );

      if (hasEmptySets) {
        setIsNoSetError(true);
        return true;
      }
    }

    return false;
  };

  const { data: workouts } = useQuery({
    queryFn: () => getWorkouts(),
    queryKey: queryKeys.getWorkouts(),
  });

  const [reportData, setReportData] = useState<
    RequestSaveLessonRequestDTO & {
      mixedWorkoutDetail?: MixedWorkoutDetail[];
    }
  >({
    userId,
    ticketId,
    lessonResultType: preferredType
      ? preferredType
      : "feedback_workout_set_weight",
    workoutTime: lessonData?.workoutSummary?.totalTime ?? 50,
    totalFeedback: lessonData?.totalFeedback ?? "",
    mixedWorkoutDetail: lessonData?.workoutDetails.map(
      (detail) =>
        workoutDetailTORequestSaveLessonRequestWorkoutDetailDTO(detail) ?? [],
    ),
    workoutDetails: [],
  });

  useEffect(() => {
    console.log(reportData);
  }, [reportData]);

  const handleCheckboxChange = (
    workoutName: string,
    part: WorkoutParts,
    isChecked: boolean,
  ) => {
    if (isChecked) {
      setAddList((prev) => [...prev, { workoutName, part }]);
      setEraseList((prev) =>
        prev.filter((item) => item.workoutName !== workoutName),
      );
    } else {
      setEraseList((prev) => [...prev, { workoutName, part }]);
      setAddList((prev) =>
        prev.filter((item) => item.workoutName !== workoutName),
      );
    }
  };

  const handleSaveInAddWorkoutDialog = () => {
    setReportData((prevState) => {
      let updatedWorkouts = prevState.mixedWorkoutDetail || [];

      // eraseList에 포함된 항목 제거
      updatedWorkouts = updatedWorkouts.filter(
        (workout) =>
          !eraseList.some(
            (item) =>
              item.workoutName === workout.workoutName &&
              item.part === workout.part,
          ),
      );

      // addList에 있는 항목을 추가
      addList.forEach(({ workoutName, part }) => {
        if (
          !updatedWorkouts.some(
            (workout) =>
              workout.workoutName === workoutName && workout.part === part,
          )
        ) {
          const matchedWorkouts = workouts?.[mapPartToKey(part)].filter(
            (workout) => workout.name === workoutName,
          );

          updatedWorkouts.push({
            id: matchedWorkouts?.[0]?.id,
            workoutName,
            part,
            sets: [],
            images: [],
            videos: [],
            feedback: "",
            imagesDetail: [],
            videosDetail: [],
            thumbnail: matchedWorkouts?.[0]?.thumbnail,
          });
        }
      });

      return {
        ...prevState,
        mixedWorkoutDetail: updatedWorkouts,
      };
    });

    setIsAddDialogOpen(false);
  };

  const renderWorkouts = (
    workoutListItem: WorkoutCategoriesDetailDTO[] | undefined,
    prevSelectedWorkouts: MixedWorkoutDetail[] | undefined,
    part: WorkoutParts,
  ) =>
    workoutListItem
      ?.filter((workout) => {
        return workout.part == part;
      })
      ?.filter((workout) => {
        // findingPart가 빈 문자열이면 필터링 없이 모든 항목을 통과시킴
        if (findingPart === "") {
          return true;
        }
        // 띄어쓰기를 무시하고 findingPart와 workout.name을 비교
        const normalizedFindingPart = findingPart
          .replace(/\s/g, "")
          .toLowerCase();
        const normalizedWorkoutName = workout.name
          .replace(/\s/g, "")
          .toLowerCase();
        return normalizedWorkoutName.includes(normalizedFindingPart);
      })
      ?.map((workout, index) => {
        const isChecked = prevSelectedWorkouts?.some(
          (item) => item.workoutName === workout.name,
        );
        console.log("isChecked" + `//${isChecked}`);

        return (
          <div key={index} css={dialogItemCSS}>
            <ListCheckBox
              defaultChecked={isChecked}
              onCheckedChange={(checked) => {
                console.log(checked);
                handleCheckboxChange(workout.name, part, !!checked);
              }}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <img
                  src={workout?.thumbnail ?? customImage}
                  css={css`
                    height: 80px;
                    width: 80px;
                  `}
                />
                <div
                  css={css`
                    color: ${colors.gray800};
                    ${typography.mobile.body2}
                  `}
                >
                  {workout.name}
                </div>
              </div>
            </ListCheckBox>
          </div>
        );
      });

  return (
    <>
      {isProcessing && <Loading />}
      {!isProcessing && (
        <div css={containerCSS}>
          <CrmHeader />
          <div css={contentsContainerCSS}>
            <Sidebar />
            <div css={contentContainerCSS}>
              <CreateContext.Provider
                value={{ data: reportData, setData: setReportData }}
              >
                <ReportTypeCheck reportType={preferredType} />
                <Separator />
                <div css={reportDescriptionContainerCSS}>
                  <div>{reportTypeInKR(reportData.lessonResultType)} 작성</div>
                  <div
                    css={css`
                      color: ${colors.orange};
                      ${typography.mobile.body2}
                    `}
                  >
                    *는 필수 항목입니다
                  </div>
                </div>
                <WorkoutTime />
                {reportData.lessonResultType != "feedback_only" && (
                  <WorkoutList />
                )}
                {reportData.lessonResultType != "feedback_only" && (
                  <Button
                    css={buttonCSS}
                    onClick={() => {
                      setFindingPart("");
                      setIsAddDialogOpen(true);
                    }}
                  >
                    <img src={plusIcon} />
                    운동 추가하기
                  </Button>
                )}
                <TotalFeedback />
                <div css={buttonContainerCSS}>
                  <Button
                    variant={"secondary"}
                    size={46}
                    onClick={saveTempReport}
                  >
                    임시저장
                  </Button>
                  <Button variant={"primary"} size={46} onClick={sendReport}>
                    PT 일지 전송
                  </Button>
                </div>
              </CreateContext.Provider>
            </div>
          </div>
          <Dialog.Root variant={"page"} open={isAddDialogOpen}>
            <Dialog.Content
              css={css`
                width: 700px;
              `}
            >
              <div css={dialogHeaderCSS}>
                <div css={dialogHeaderTextCSS}>운동 추가하기</div>
                <img
                  src={closeIcon}
                  css={closeIconCSS}
                  onClick={() => {
                    setIsAddDialogOpen(!isAddDialogOpen);
                  }}
                />
              </div>
              <div css={addWorkoutSubHeaderContainerCSS}>
                <Textarea
                  placeholder={"찾고자 하는 운동을 검색해주세요"}
                  onChange={(event) => {
                    setFindingPart(event.target.value);
                  }}
                />
                <RadioGroup.Root
                  defaultValue={selectedPart}
                  onValueChange={(part: WorkoutParts | "total") => {
                    setFindingPart("");
                    setSelectedPart(part);
                  }}
                  styles={labelComponentListContainerCSS}
                >
                  <RadioGroup.Chips value="total">
                    <div>전체</div>
                  </RadioGroup.Chips>
                  <RadioGroup.Chips value="lower_body">
                    <div>하체</div>
                  </RadioGroup.Chips>
                  <RadioGroup.Chips value="chest">
                    <div>가슴</div>
                  </RadioGroup.Chips>
                  <RadioGroup.Chips value="back">
                    <div>등</div>
                  </RadioGroup.Chips>
                  <RadioGroup.Chips value="shoulders">
                    <div>어깨</div>
                  </RadioGroup.Chips>
                  <RadioGroup.Chips value="arms">
                    <div>팔</div>
                  </RadioGroup.Chips>
                  <RadioGroup.Chips value="abs">
                    <div>복근</div>
                  </RadioGroup.Chips>
                  <RadioGroup.Chips value="etc">
                    <div>기타</div>
                  </RadioGroup.Chips>
                </RadioGroup.Root>
              </div>
              <div css={dialogBodyContainerCSS}>
                {(selectedPart == "total" || selectedPart == "lower_body") &&
                  renderWorkouts(
                    [
                      ...(workouts?.lowerBody || []),
                      ...(workouts?.custom || []),
                    ],
                    reportData.mixedWorkoutDetail,
                    "lower_body",
                  )}
                {(selectedPart == "total" || selectedPart == "chest") &&
                  renderWorkouts(
                    [...(workouts?.chest || []), ...(workouts?.custom || [])],
                    reportData.mixedWorkoutDetail,
                    "chest",
                  )}
                {(selectedPart == "total" || selectedPart == "back") &&
                  renderWorkouts(
                    [...(workouts?.back || []), ...(workouts?.custom || [])],
                    reportData.mixedWorkoutDetail,
                    "back",
                  )}
                {(selectedPart == "total" || selectedPart == "shoulders") &&
                  renderWorkouts(
                    [
                      ...(workouts?.shoulders || []),
                      ...(workouts?.custom || []),
                    ],
                    reportData.mixedWorkoutDetail,
                    "shoulders",
                  )}
                {(selectedPart == "total" || selectedPart == "arms") &&
                  renderWorkouts(
                    [...(workouts?.arms || []), ...(workouts?.custom || [])],
                    reportData.mixedWorkoutDetail,
                    "arms",
                  )}
                {(selectedPart == "total" || selectedPart == "abs") &&
                  renderWorkouts(
                    [...(workouts?.abs || []), ...(workouts?.custom || [])],
                    reportData.mixedWorkoutDetail,
                    "abs",
                  )}
                {(selectedPart == "total" || selectedPart == "etc") &&
                  renderWorkouts(
                    [...(workouts?.etc || []), ...(workouts?.custom || [])],
                    reportData.mixedWorkoutDetail,
                    "etc",
                  )}
              </div>
              <div css={saveButtonContainerCSS}>
                <Button
                  variant={"secondary"}
                  css={saveButtonCSS}
                  onClick={() => {
                    handleSaveInAddWorkoutDialog();
                    setIsAddCustomDialogOpen(true);
                  }}
                >
                  커스텀 운동 추가
                </Button>
                <Button
                  variant={"primary"}
                  css={saveButtonCSS}
                  onClick={handleSaveInAddWorkoutDialog}
                >
                  저장하기
                </Button>
              </div>
            </Dialog.Content>
          </Dialog.Root>
          <AddCustomWorkoutDialog
            isAddCustomDialogOpen={isAddCustomDialogOpen}
            setIsAddCustomDialogOpen={setIsAddCustomDialogOpen}
          />
          <SendErrorDialog
            isNoSetError={isNoSetError}
            setIsNoSetError={setIsNoSetError}
            isNoWorkoutError={isNoWorkoutError}
            setIsNoWorkoutError={setIsNoWorkoutError}
            isRequireDataMissingError={isRequireDataMissingError}
            setIsRequireDataMissingError={setIsRequireDataMissingError}
          />

          {lessonData && (
            <ReportShareDialog
              isOpen={isShareDialogOpen}
              setIsOpen={(state) => {
                setIsShareDialogOpen(state);
              }}
              lessonData={lessonData}
              userId={userId}
              scheduleSlotId={scheduleSlotId}
              screenName={ScreenName.web_pt_report_write}
              onClose={() => navigate("crm/")}
            />
          )}
        </div>
      )}
    </>
  );

  interface WorkoutItem {
    workoutName: string;
    part: WorkoutParts;
  }
}

const labelComponentListContainerCSS = css`
  display: flex;
  justify-content: space-between;
  padding: 14px 0;
`;

const addWorkoutSubHeaderContainerCSS = css`
  padding: 0 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const closeIconCSS = css`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const dialogHeaderTextCSS = css`
  width: 100%;
  text-align: center;
  ${typography.mobile.heading3}
`;

const dialogHeaderCSS = css`
  display: flex;
  padding: 14px;
`;

const reportDescriptionContainerCSS = css`
  display: flex;
  gap: 20px;
  align-items: baseline;
`;

const buttonContainerCSS = css`
  padding-top: 40px;
  display: flex;
  gap: 10px;
  justify-content: right;
`;

const dialogItemCSS = css`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const dialogBodyContainerCSS = css`
  display: flex;
  flex-direction: column;
  height: 450px;
  padding: 0 24px 20px 24px;
  overflow-y: auto;
`;

const saveButtonContainerCSS = css`
  padding: 24px;
  background-color: white;
  display: flex;
  gap: 10px;
`;

const saveButtonCSS = css`
  width: 100%;
  padding: 12px 16px;
`;

const buttonCSS = css`
  background: black;
  color: white;
  border-radius: 200px;
  padding: 16px 22px;
  display: flex;
  width: fit-content;
  align-self: center;
`;

const contentContainerCSS = css`
  padding: ${CrmContentVerticalPadding}px ${CrmContentHorizontalPadding}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-height: 100vh;
  height: auto;
`;

const contentsContainerCSS = css`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: auto;
`;

const containerCSS = css`
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: auto;
`;

import { css } from "@emotion/react";
import CreateContext from "../../createContext";
import { useContext } from "react";
import { typography } from "design-system/styles/typography/typography";
import { colors } from "design-system/styles/colors";
import { Textarea } from "design-system/components/Textarea/Textarea";

export function TotalFeedback() {
  const { data, setData } = useContext(CreateContext);

  return (
    <div css={containerCSS}>
      <div css={titleCSS}>
        종합 소견 작성
        <span
          css={css`
            color: ${colors.orange};
          `}
        >
          *
        </span>
      </div>
      <Textarea
        value={data?.totalFeedback}
        onChange={(event) => {
          setData((prevData) => ({
            ...prevData,
            totalFeedback: event.target.value, // 숫자로 변환하여 설정
          }));
        }}
      />
    </div>
  );
}

const titleCSS = css`
  ${typography.mobile.heading4};
  color: ${colors.gray600};
`;
const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

import { WorkoutSetDTO } from "../../../../api/trainer/users/usersTypes";
import { colors } from "design-system/styles/colors";
import { css } from "@emotion/react";
import { typography } from "design-system/styles/typography/typography";

type WorkoutSetTableProps = {
  workoutSet: WorkoutSetDTO[];
};

export function SetTable({ workoutSet }: WorkoutSetTableProps) {
  return (
    <div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ backgroundColor: colors.gray200 }} css={titleTextCSS}>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>
              세트수
            </th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>무게</th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>횟수</th>
            <th style={{ border: "1px solid #ccc", padding: "10px" }}>보조</th>
          </tr>
        </thead>
        <tbody>
          {workoutSet.map((row, index) => (
            <tr key={index} css={[titleTextCSS, contentCSS]}>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                {index + 1 + "set"}
              </td>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                {row.weight}
              </td>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                {row.count}
              </td>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                {row.assistant ? "O" : "X"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const titleTextCSS = css`
  color: ${colors.gray900};
  ${typography.mobile.body2}
`;

const contentCSS = css`
  background: ${colors.gray25};
`;

import { SVGProps } from 'react'

export default function PfDelete({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      fill="currentColor"
      viewBox="0 0 72 72"
      {...props}
    >
      <path d="M36 68c17.673 0 32-14.327 32-32C68 18.327 53.673 4 36 4 18.327 4 4 18.327 4 36c0 17.673 14.327 32 32 32Zm13.414-18.586a2 2 0 0 1-2.828 0L36 38.828 25.414 49.414a2 2 0 1 1-2.828-2.828L33.172 36 22.586 25.414a2 2 0 1 1 2.828-2.828L36 33.172l10.586-10.586a2 2 0 1 1 2.828 2.828L38.828 36l10.586 10.586a2 2 0 0 1 0 2.828Z" />
    </svg>
  )
}

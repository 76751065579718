import Lottie from "design-system/components/Lottie/Lottie";
import { pfLoadingBlack } from "design-system/styles/assets/lotties";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { css } from "@emotion/react";

export default function Loading() {
  return (
    <div css={loadingCSS}>
      <Lottie json={pfLoadingBlack} styles={lottieCSS} />
      <p css={loadingTextCSS}>잠시만 기다려주세요</p>
    </div>
  );
}

const loadingCSS = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  background: white;
`;

const loadingTextCSS = css`
  ${typography.Button2};
  color: ${colors.default.Gray300};
`;

const lottieCSS = css`
  width: 40px;
  height: 40px;
`;

import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  RefAttributes,
  useId,
} from "react";
// import { Label } from "@design-system/components/atom/Label/Label";
import { colors } from "design-system/styles/colors";
import { css, Interpolation, Theme } from "@emotion/react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Label } from "design-system/components/Label/Label";

interface RadioGroupItemProps
  extends ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {
  variant?: "circle" | "check";
  isShowIndicator?: boolean;
  align?: "top" | "center" | "bottom";
  styles?: Interpolation<Theme>;
}

const Root = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Root>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      css={[radioGroupRootCSS, styles]}
      {...props}
      ref={ref}
    />
  );
});

Root.displayName = RadioGroupPrimitive.Root.displayName;

const Item = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(
  (
    {
      styles,
      children,
      variant = "circle",
      align = "top",
      isShowIndicator = true,
      ...props
    },
    ref,
  ) => {
    return (
      <RadioGroupPrimitive.Item
        css={[
          radioGroupItemCSS,
          variant === "circle" && radioGroupItemCircleCSS,
          align === "top" && checkboxAlignTopCSS,
          align === "center" && checkboxAlignCenterCSS,
          align === "bottom" && checkboxAlignBottomCSS,
          styles,
        ]}
        ref={ref}
        {...props}
      >
        {children}
        {isShowIndicator && (
          <RadioGroupPrimitive.Indicator css={radioGroupIndicatorCSS}>
            {variant === "circle" && (
              <span data-circle-icon css={radioGroupCircleCSS} />
            )}
            {/*{variant === 'check' && (*/}
            {/*  <PoDone css={doneIconCSS} data-check-icon />*/}
            {/*)}*/}
          </RadioGroupPrimitive.Indicator>
        )}
      </RadioGroupPrimitive.Item>
    );
  },
);

Item.displayName = RadioGroupPrimitive.Item.displayName;

interface RadioGroupItemWithLabel
  extends Omit<RadioGroupItemProps & RefAttributes<HTMLButtonElement>, "ref">,
    RefAttributes<HTMLButtonElement> {
  position?: "left" | "right";
}

const ItemWithLabel = ({
  children,
  disabled,
  position = "left",
  styles,
  ...props
}: RadioGroupItemWithLabel) => {
  const id = useId();

  return (
    <div
      css={[
        radioGroupItemWithLabelCSS,
        disabled && radioGroupItemWithLabelDisabledCSS,
        styles,
      ]}
    >
      <Item
        id={id}
        disabled={disabled}
        styles={[
          position === "left" && radioGroupItemPositionLeftCSS,
          position === "right" && radioGroupItemPositionRightCSS,
        ]}
        {...props}
      />
      <Label htmlFor={id}>{children}</Label>
    </div>
  );
};

const Chips = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(
  (
    {
      styles,
      children,
      variant = "circle",
      align = "top",
      isShowIndicator = false,
      ...props
    },
    ref,
  ) => {
    return (
      <RadioGroupPrimitive.Item css={[chipCSS]} ref={ref} {...props}>
        {children}
        {isShowIndicator && (
          <RadioGroupPrimitive.Indicator css={chipCSS}>
            <span data-circle-icon css={chipCSS} />
          </RadioGroupPrimitive.Indicator>
        )}
      </RadioGroupPrimitive.Item>
    );
  },
);

const chipCSS = css`
  background: ${colors.gray50};
  border-radius: 100px;
  padding: 4px 12px;
  color: ${colors.black};

  &[data-state="checked"] {
    background: ${colors.black};
    color: ${colors.gray25};
  }
`;

export { Root, Item, ItemWithLabel, Chips };

const radioGroupRootCSS = css`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

const radioGroupItemCSS = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  background-color: transparent;
  //margin-bottom: auto;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
  }
`;

const radioGroupItemCircleCSS = css`
  border: 1px solid ${colors.default.Gray600};
  border-radius: 100%;

  :disabled {
    border: 1px solid ${colors.default.Gray800};
  }

  &[data-state="checked"] {
    border: 0;
  }
`;

const checkboxAlignTopCSS = css`
  //margin-bottom: auto;
`;

const checkboxAlignCenterCSS = css`
  margin-top: auto;
  margin-bottom: auto;
`;

const checkboxAlignBottomCSS = css`
  margin-top: auto;
`;

const radioGroupIndicatorCSS = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-disabled] {
    [data-circle-icon] {
      background-color: ${colors.lime900};
    }

    [data-check-icon] {
      svg {
        path {
          fill: currentColor;
          color: ${colors.lime900};
        }
      }
    }
  }
`;

const radioGroupCircleCSS = css`
  border-radius: 100%;
  width: 100%;
  height: 100%;
  background-color: ${colors.lime900};

  :after {
    display: flex;
    content: "";
    background-color: ${colors.default.White};
    border-radius: 100%;
    width: 33%;
    height: 33%;
    transform: translate(100%, 100%);
  }
`;

const doneIconCSS = css`
  color: ${colors.lime900};
  width: 24px;
  height: 24px;
`;

const radioGroupItemWithLabelCSS = css`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 16px;

  cursor: pointer;
  color: ${colors.default.Gray};
`;

const radioGroupItemWithLabelDisabledCSS = css`
  cursor: not-allowed;
`;

const radioGroupItemPositionLeftCSS = css`
  order: 0;
`;

const radioGroupItemPositionRightCSS = css`
  order: 1;
`;

import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorTemplate from "./common/ErrorTemplate/ErrorTemplate";

// HeaderSidebarLayout에 getMembersMe API call을 하기 때문에, ErrorBoundary를 두번 감싸 사용합니다.
export default function GlobalErrorBoundary({ children }: PropsWithChildren) {
  return (
    <ErrorBoundary FallbackComponent={ErrorTemplate}>{children}</ErrorBoundary>
  );
}

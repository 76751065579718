import { css } from "@emotion/react";
import CreateContext from "../../createContext";
import { useContext } from "react";
import { typography } from "design-system/styles/typography/typography";
import { Input } from "design-system/components/Input/Input";
import { colors } from "design-system/styles/colors";

export function WorkoutTime() {
  const { data, setData } = useContext(CreateContext);

  return (
    <div css={containerCSS}>
      <div css={titleCSS}>
        총 운동시간(분)
        <span
          css={css`
            color: ${colors.orange};
          `}
        >
          *
        </span>
      </div>
      <Input
        value={data?.workoutTime}
        onChange={(event) => {
          const value = event.target.value.replace("", "").trim(); // '분'을 제외하고 공백 제거
          // 숫자가 아니면 리턴
          if (isNaN(Number(value))) {
            return;
          } else {
            setData((prevData) => ({
              ...prevData,
              workoutTime: Number(value), // 숫자로 변환하여 설정
            }));
          }
        }}
      />
    </div>
  );
}

const titleCSS = css`
  ${typography.mobile.heading4};
  color: ${colors.gray600};
`;
const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

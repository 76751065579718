import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import Button from "design-system/components/Button/Button";
import calendarIcon from "resource/common/icon/ic_calender.svg";
import { typography } from "design-system/styles/typography/typography";
import { useContext } from "react";
import SidebarContext from "./sidebarContext";
import { ReportWrittenType } from "../../api/common/commonEnumType";
import { useNavigate } from "react-router";

export type SidebarState = ReportWrittenType | "all" | "none";

export function Sidebar() {
  const { sidebarState, setSidebarState } = useContext(SidebarContext);

  const navigate = useNavigate();

  return (
    <div css={containerCSS}>
      <div css={sidebarContainerCSS}>
        <SidebarItem iconSrc={calendarIcon} title={"PT 일지"} />
        <SidebarSubItem
          title={`전체`}
          isSelected={sidebarState == "all"}
          onClick={() => {
            if (sidebarState === "none") {
              navigate(`/crm/report/list?state=all`);
            } else {
              setSidebarState("all");
            }
          }}
        />
        <SidebarSubItem
          title={`미작성`}
          isSelected={sidebarState == "not_written"}
          onClick={() => {
            if (sidebarState === "none") {
              navigate(`/crm/report/list?state=not_written`);
            } else {
              setSidebarState("not_written");
            }
          }}
        />
        <SidebarSubItem
          title={`작성중`}
          isSelected={sidebarState == "writing"}
          onClick={() => {
            if (sidebarState === "none") {
              navigate(`/crm/report/list?state=writing`);
            }
            setSidebarState("writing");
          }}
        />
        <SidebarSubItem
          title={`작성완료`}
          isSelected={sidebarState == "completed"}
          onClick={() => {
            if (sidebarState === "none") {
              navigate(`/crm/report/list?state=completed`);
              return;
            }
            setSidebarState("completed");
          }}
        />
      </div>
      <div css={footerContainerCSS}>
        <a
          css={footerGuidTextCSS}
          href="https://fitsyou.notion.site/a556e709df3042f2bbd0f46f8ab122a1"
          target="_blank"
          rel="noopener noreferrer"
        >
          핏츠유 가이드
        </a>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div css={footerSubTextCSS}>프시케랩</div>
          <div css={footerSubTextCSS}>070-7954-6956</div>
          <div css={footerSubTextCSS}>contact@fitsyou.co.kr</div>
        </div>
      </div>
    </div>
  );
}

interface SidebarItemProps {
  iconSrc: string;
  title: string;
}

const containerCSS = css`
  width: 276px;
  min-width: 250px;
  border-right: 1px solid ${colors.Black_a10};
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;

const itemTextCSS = css`
  ${typography.Button2};
`;

const itemContainerCSS = css`
  padding: 16px 0;
  align-items: center;
  display: flex;
  gap: 4px;
`;
const buttonCSS = (isSelected: boolean) => css`
  border: none;
  background: ${isSelected ? colors.gray50 : "transparent"};
  padding: 8px 24px;
  width: 100%;
  justify-content: flex-start;
  border-radius: 4px;
`;

const footerContainerCSS = css`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  height: 200px;
  gap: 25px;
`;

const footerGuidTextCSS = css`
  ${typography.mobile.heading3};
  color: ${colors.gray700};
`;
const footerSubTextCSS = css`
  ${typography.mobile.body2};
  color: ${colors.gray400};
`;

const sidebarContainerCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* 요소들을 상단으로 */
  align-items: flex-start; /* 가로 축도 상단 정렬 */
`;

interface SidebarSubItemProps {
  title: string;
  isSelected: boolean;
  onClick: () => void;
}

function SidebarItem({ iconSrc, title }: SidebarItemProps) {
  return (
    <div css={itemContainerCSS}>
      <img src={iconSrc} alt={"icon"} />
      <span css={itemTextCSS}>{title}</span>
    </div>
  );
}

function SidebarSubItem({ title, isSelected, onClick }: SidebarSubItemProps) {
  return (
    <Button variant={"tertiary"} css={buttonCSS(isSelected)} onClick={onClick}>
      <div>
        <span css={itemTextCSS}>{title}</span>
      </div>
    </Button>
  );
}

import { css } from "@emotion/react";
import { fontWeight } from "design-system/styles/typography/typography";
import { getWidthMediaQuery } from "../../styles/mediaQueries";

export interface QnaSectionProps {
  question: string;
  answer: string;
}

/** @jsxImportSource @emotion/react */
export function QnaSection({ question, answer }: QnaSectionProps) {
  return (
    <div css={containerCSS}>
      <span css={questionTextCSS}>{question}</span>
      <span css={answerTextCSS}>{answer}</span>
    </div>
  );
}

const questionTextCSS = css`
  color: #a3a3a3;
  font-size: 14px;
  font-weight: ${fontWeight.Medium};
  line-height: 150%; /* 22.4px */

  ${getWidthMediaQuery("pc")} {
    font-size: 24px;
  }
`;

const answerTextCSS = css`
  color: #fff;
  font-size: 14px;
  font-weight: ${fontWeight.Medium};
  line-height: 140%; /* 22.4px */

  ${getWidthMediaQuery("pc")} {
    font-size: 26px;
    line-height: 150%;
  }
`;

const containerCSS = css`
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 10px;

  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(40px);

  ${getWidthMediaQuery("pc")} {
    padding: 36px 40px;
  }
`;

import { css } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { WorkoutDetailDTO } from "../../../../api/trainer/users/usersTypes";
import { workoutPartsInKR } from "../../../../api/common/commonEnumType";
import { typography } from "design-system/styles/typography/typography";
import Button from "design-system/components/Button/Button";
import arrowUpIcon from "resource/common/icon/ic_arrow_up.svg";
import { useState } from "react";
import { Textarea } from "design-system/components/Textarea/Textarea";
import { ImageView } from "design-system/components/ImageView/ImageView";
import { SetTable } from "../SetTable/SetTable";
import customImage from "design-system/styles/assets/images/customImage.svg";
import messageImage from "design-system/styles/assets/images/message-square-01.svg";
import { PrevFeedbackDialog } from "../PrevFeedbackDialog/PrevFeedbackDialog";

interface WorkoutItemProps {
  item: WorkoutDetailDTO;
  order: number;
  userId: number;
}

export function WorkoutItem({ item, order, userId }: WorkoutItemProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  return (
    <div
      css={[
        containerCSS,
        css`
          background: ${isOpen ? colors.lightGray : colors.gray25};
        `,
      ]}
    >
      <div css={mainContainerCSS} onClick={() => setIsOpen(!isOpen)}>
        <div css={upperContainerCSS}>
          <div css={badgeContainerCSS}>
            <div css={badgeCSS}>{order}번째</div>
            {item.sets.length > 0 && (
              <div css={badgeCSS}>{item.sets.length}세트</div>
            )}
            <div css={badgeCSS}>{workoutPartsInKR(item.part)}</div>
            {item.sets.length > 0 && (
              <div css={badgeCSS}>
                {item.sets.reduce(
                  (accumulator, currentSet) =>
                    accumulator + currentSet.weight * currentSet.count,
                  0,
                )}
                KG
              </div>
            )}
          </div>
        </div>
        <div css={bodyContainerCSS}>
          <ImageView
            src={item.thumbnail ?? customImage}
            width={116}
            height={116}
            variant={"square"}
            isClickable={false}
          />
          <div css={workoutNameTextCSS}>{item.workoutName}</div>
          <Button variant={"icon"}>
            {(item.sets.length !== 0 ||
              item.images.length !== 0 ||
              item.videos.length !== 0 ||
              item.feedback) && (
              <>
                {!isOpen ? (
                  <img
                    src={arrowUpIcon}
                    style={{ transform: "rotate(180deg)" }}
                    height={40}
                    width={40}
                  />
                ) : (
                  <img src={arrowUpIcon} height={40} width={40} />
                )}
              </>
            )}
          </Button>
        </div>
      </div>
      <div css={prevFeedbackCSS} onClick={() => setIsFeedbackDialogOpen(true)}>
        <img
          src={messageImage}
          css={css`
            height: 15px;
            width: 15px;
          `}
        />
        이전 피드백
      </div>
      {isOpen && (
        <div css={foldedContainerCSS}>
          {item.feedback && (
            <div css={feedbackContainerCSS}>
              <div css={feedbackTextCSS}>피드백</div>
              <Textarea
                isAutoHeight={true}
                value={item.feedback}
                styles={css`
                  ${typography.mobile.heading1}
                `}
              />
            </div>
          )}
          {(item.images.length > 0 || item.videos.length > 0) && (
            <div css={feedbackContainerCSS}>
              <div css={feedbackTextCSS}>사진 및 동영상</div>
              <div css={imageListContainerCSS}>
                {item.videos.map((video) => (
                  <ImageView
                    key={video.id}
                    src={video.thumbnail}
                    videoSrc={video.url}
                    variant={"square"}
                    type={"video"}
                  />
                ))}
                {item.images.map((image) => (
                  <ImageView
                    key={image.id}
                    src={image.url}
                    variant={"square"}
                    type={"image"}
                  />
                ))}
              </div>
            </div>
          )}
          {item.sets.length > 0 && <SetTable workoutSet={item.sets} />}
        </div>
      )}
      <PrevFeedbackDialog
        isOpen={isFeedbackDialogOpen}
        setIsOpen={(state) => setIsFeedbackDialogOpen(state)}
        workout={item}
        userId={userId}
      />
    </div>
  );
}

const prevFeedbackCSS = css`
  padding: 20px;
  display: flex;
  justify-content: center;
  border: 1px solid ${colors.gray200};
  border-top: none;
  background: white;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
  ${typography.mobile.body2};
  align-items: center;
  gap: 8px;
`;

const imageListContainerCSS = css`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const feedbackTextCSS = css`
  color: ${colors.gray800};
  ${typography.mobile.body2}
  min-width: 107px;
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
`;

const workoutNameTextCSS = css`
  color: ${colors.gray900};
  ${typography.mobile.heading2};
  width: 100%;
`;

const bodyContainerCSS = css`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const upperContainerCSS = css`
  display: flex;
`;
const badgeContainerCSS = css`
  display: flex;
  gap: 8px;
  ${typography.mobile.body2}
`;

const badgeCSS = css`
  padding: 4px 6px;
  background: ${colors.gray800};
  color: white;
  border-radius: 2px;
`;

const mainContainerCSS = css`
  padding: 30px;
  border-radius: 8px 8px 0 0;
  border: 1px solid ${colors.gray200};
  background: ${colors.gray25};
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
`;

const foldedContainerCSS = css`
  padding: 50px 40px 40px 40px;
  border-radius: 0 0 8px 8px;
  background: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const feedbackContainerCSS = css`
  display: flex;
  gap: 40px;
`;

/**
 * TODO(JUN) - 운동정보, 이전 피드백 관련 사항 추후 구현 예정
 */
const bottomButtonListContainerCSS = css`
  play: fl x;
`;

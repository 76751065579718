import React, { useState } from "react";
import { css } from "@emotion/react";
import Button from "design-system/components/Button/Button";
import { typography } from "design-system/styles/typography/typography";
import mobileEx1 from "resource/trainer/homeResource/mobile_ex1.png";
import mobileEx2 from "resource/trainer/homeResource/mobile_ex2.png";
import pc1img from "resource/trainer/homeResource/pc1.png";
import pc2img from "resource/trainer/homeResource/pc2.png";

export function DescriptionFrame() {
  const [isPC, setIsPC] = useState(true);

  return (
    <div css={containerCSS}>
      <div css={buttonContainerCSS}>
        <Button
          css={[buttonCSS, isPC && activeButtonCSS]}
          onClick={() => setIsPC(true)}
        >
          PC
        </Button>
        <Button
          css={[buttonCSS, !isPC && activeButtonCSS]}
          onClick={() => setIsPC(false)}
        >
          MOBILE
        </Button>
      </div>
      {isPC && (
        <div css={descriptionCSS}>
          <div css={titleCSS}>
            PC환경에서도 일지 조회 및 작성을 진행 할 수 있어요.
          </div>
          <div css={subTitleCSS}>
            수업 중 기록한 사진 및 동영상을 잠시 저장해두었다가
            <br />
            PC에서 이어서 작성해보세요!
          </div>
        </div>
      )}
      {!isPC && (
        <div css={descriptionCSS}>
          <div css={titleCSS}>
            일지 작성은 물론, 일정 관리까지 모바일로 쉽고 빠르게
          </div>
          <div css={subTitleCSS}>
            수업 중 기록한 사진 및 동영상을 잠시 저장해두었다가
            <br />
            PC에서 이어서 작성해보세요!
          </div>
        </div>
      )}
      {isPC && (
        <div css={pcImageContainerCSS}>
          <img src={pc1img} css={pcImage1CSS} />

          <img src={pc2img} css={pcImage2CSS} />
        </div>
      )}
      {!isPC && (
        <div css={mobileImageContainerCSS}>
          <img src={mobileEx1} css={mobileImageCSS} />
          <div
            css={css`
              margin-top: 120px;
            `}
          >
            <img src={mobileEx2} css={mobileImageCSS} />
          </div>
        </div>
      )}
    </div>
  );
}

const pcImageContainerCSS = css`
  margin-top: 90px;
  width: 100%;
  display: flex;
  gap: 306px;
  flex-direction: column;
  align-items: center;
`;
const pcImage1CSS = css`
  width: 705px;
  object-fit: contain;
`;
const pcImage2CSS = css`
  width: 855px;
  object-fit: contain;
`;

const mobileImageCSS = css`
  width: 420px;
  object-fit: contain;
`;

const mobileImageContainerCSS = css`
  margin-top: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0px;
`;

const descriptionCSS = css`
  padding: 135px 0 0 35px;
  width: 100%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 49px;
`;

const titleCSS = css`
  color: #fff;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  letter-spacing: -2.4px;
`;

const subTitleCSS = css`
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 48.667px */
  letter-spacing: -1.521px;
`;
const containerCSS = css`
  padding: 110px 0 150px 0;
`;

const buttonContainerCSS = css`
  padding-left: 270px;
  display: flex;
  gap: 137px;
`;

const buttonCSS = css`
  color: #a5a5a5; /* 기본 글자색 */
  background: none;
  ${typography.Body3}
`;

const activeButtonCSS = css`
  color: white; /* 선택된 버튼의 글자색 */
  text-decoration: underline;
  background: none;
  ${typography.Body3}
  text-underline-offset: 10px;
`;

const tabTriggerCSS = css``;

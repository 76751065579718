import backgroundImg from "../../resource/trainee/fram2bg.svg";
import { css } from "@emotion/react";
import { getWidthMediaQuery } from "../../styles/mediaQueries";
import InfoCard, { InfoCardProps } from "./InfoCard";
import locationImg from "../../resource/trainee/frame2/cardLocation.png";
import expertImg from "../../resource/trainee/frame2/cardRelevant.png";
import reviewImg from "../../resource/trainee/frame2/cardReview.png";

import locationImg980 from "../../resource/trainee/frame2/cardLocation980.png";
import expertImg980 from "../../resource/trainee/frame2/cardRelevant980.png";
import reviewImg980 from "../../resource/trainee/frame2/cardReview980.png";
import { useWindowSize } from "../../hoock/useWindowSize";

/** @jsxImportSource @emotion/react */
export default function Frame2() {
  const { isMobile, isDefault } = useWindowSize();
  const info1: InfoCardProps = {
    imageSrc: isDefault ? expertImg : isMobile ? expertImg : expertImg980,
    body: "이해하기 힘든 자격 및 수상내역 대신 철저하게 분석해 드릴게요.",
    title: "전문성은 알기 쉽게",
  };

  const info2: InfoCardProps = {
    imageSrc: isDefault ? locationImg : isMobile ? locationImg : locationImg980,
    body: "가격,위치등 PT 등록에 필요한 모든것을 보기 쉽게  정리해드려요",
    title: "한눈에 비교할 수 있게",
  };

  const info3: InfoCardProps = {
    imageSrc: isDefault ? reviewImg : isMobile ? reviewImg : reviewImg980,
    body: "변별력 없는 가짜 후기들이 아닌 실제 회원님들의 생생한 후기를 만나보세요.",
    title: "후기는 날카롭게",
  };

  return (
    <div css={frame2ContainerCSS}>
      <div css={imageContainerCSS}>
        <img src={backgroundImg} css={imageCSS} alt={"트레이너 찾기 핵심"} />
      </div>
      <div css={contentContainer}>
        {/*TODO 레포트 Image 수정 필요*/}
        <InfoCard {...info1} />
        <InfoCard {...info2} />
        <InfoCard {...info3} />
      </div>
    </div>
  );
}

const imageCSS = css`
  width: 100%;
  object-fit: cover;
  height: 100%;

  ${getWidthMediaQuery("pc")} {
  }
`;

const imageContainerCSS = css`
  position: absolute;
  z-index: -1;
  top: 804px;
  height: 537px;

  ${getWidthMediaQuery("pc")} {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const contentContainer = css`
  display: flex;
  position: absolute;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 29px;
  gap: 40px;

  ${getWidthMediaQuery("pc")} {
    flex-direction: row;
    gap: 60px;
    padding-top: 9.2vw;
    width: auto;
  }

  ${getWidthMediaQuery("default")} {
    gap: 60px;
  }
`;

const frame2ContainerCSS = css`
  position: relative;
  display: flex;
  z-index: -2;
  height: 1341px;
  justify-content: center;
  padding-top: 28px;

  ${getWidthMediaQuery("pc")} {
    height: 1080px;
    padding-top: 0;
  }
`;

import {css} from "@emotion/react";
import {fontFamily, fontWeight,} from "design-system/styles/typography/typography";
import {getWidthMediaQuery} from "../../styles/mediaQueries";

/** @jsxImportSource @emotion/react */
export default function Frame5() {
  return (
    <div css={containerCSS}>
      <div css={serviceTextContainer}>
        <span css={text1CSS}>나에게 딱 맞는 PT쌤을 만나는 곳</span>
        <span css={text2CSS}>FitsYou</span>
      </div>
      <div css={ceoInfoContainerCSS}>
        <span css={ceoInfoText4CSS}>핏츠유</span>
        <span css={ceoInfoText4CSS}>대표 심준형</span>
        <span css={ceoInfoText4CSS}>070-7954-6956</span>
      </div>
      <span css={text3CSS}>© 2024 fitsyou</span>
    </div>
  );
}

const serviceTextContainer = css`
  display: flex;
  flex-direction: column;
`;
const text3CSS = css`
  margin-top: 20px;
  color: #a3acae;
  font-family: ${fontFamily.pretendard}, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
  letter-spacing: -0.15px;

  ${getWidthMediaQuery("pc")} {
    margin-top: 0;
  }
`;

const ceoInfoContainerCSS = css`
  margin-top: 20px;
  display: flex;
  gap: 8px;
  flex-direction: column;

  ${getWidthMediaQuery("pc")} {
    margin-top: 0;
  }
`;

const text1CSS = css`
  color: #a3acae;
  font-size: 18px;
  font-style: normal;
  font-weight: ${fontWeight.Bold};
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;

  ${getWidthMediaQuery("pc")} {
    font-size: 24px;
  }
`;
const text2CSS = css`
  color: #e0f569;
  font-family: ${fontFamily.natomPro}, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  ${getWidthMediaQuery("pc")} {
    font-size: 60px;
  }
`;

const ceoInfoText4CSS = css`
  color: #a3acae;
  font-family: ${fontFamily.pretendard}, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 22.5px */
  letter-spacing: -0.15px;
`;

const containerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 95px 20px;
  height: 408px;
  border-top: 1px solid rgba(89, 89, 89, 0.5);
  background: rgba(18, 19, 18, 0.5);
  background-color: #12131280;
  background-color: black;
  box-shadow: 0 10px 12px 0 rgba(37, 34, 45, 0.08);
  backdrop-filter: blur(150px);

  ${getWidthMediaQuery("pc")} {
    flex-direction: row;
    gap: 239px;
    height: 280px;
    justify-content: center;
  }
`;

import { SVGProps } from 'react'

export default function PfPhoto({ ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      fill="currentColor"
      viewBox="0 0 72 72"
      {...props}
    >
      <path d="M36 51.975c3.6 0 6.625-1.225 9.075-3.675 2.45-2.45 3.675-5.475 3.675-9.075 0-3.65-1.225-6.688-3.675-9.113-2.45-2.424-5.475-3.637-9.075-3.637-3.65 0-6.688 1.212-9.113 3.637-2.425 2.426-3.637 5.463-3.637 9.113 0 3.6 1.212 6.625 3.637 9.075 2.425 2.45 5.463 3.675 9.113 3.675Zm0-4.5c-2.4 0-4.375-.788-5.925-2.363-1.55-1.575-2.325-3.537-2.325-5.887 0-2.4.775-4.375 2.325-5.925 1.55-1.55 3.525-2.325 5.925-2.325 2.35 0 4.313.775 5.888 2.325 1.575 1.55 2.362 3.525 2.362 5.925 0 2.35-.788 4.313-2.362 5.887-1.575 1.575-3.538 2.363-5.888 2.363ZM10.5 63c-1.2 0-2.25-.45-3.15-1.35C6.45 60.75 6 59.7 6 58.5V20.025c0-1.15.45-2.188 1.35-3.112.9-.925 1.95-1.388 3.15-1.388h11.025l4.125-4.95c.4-.55.9-.95 1.5-1.2A5.02 5.02 0 0 1 29.1 9h13.8c.7 0 1.35.125 1.95.375.6.25 1.1.65 1.5 1.2l4.125 4.95H61.5c1.15 0 2.188.463 3.112 1.388.925.924 1.388 1.962 1.388 3.112V58.5c0 1.2-.463 2.25-1.388 3.15-.924.9-1.962 1.35-3.112 1.35h-51Z" />
    </svg>
  )
}

import { ImgHTMLAttributes, forwardRef } from 'react'

import { Theme, Interpolation } from '@emotion/react'

export const SuspenseImg = forwardRef<
  HTMLImageElement,
  ImgHTMLAttributes<HTMLImageElement> & {
    styles?: Interpolation<Theme>
  }
>(({ src, styles, ...props }, ref) => {
  const emptyStringCheckedSrc = src || 'empty'

  imgCache.read(emptyStringCheckedSrc)

  return <img ref={ref} css={styles} src={emptyStringCheckedSrc} {...props} />
})

SuspenseImg.displayName = 'SuspenseImg'

interface ImgCacheInterface {
  _cache: Record<string, Promise<void> | boolean>
  read(src?: string): Promise<void> | boolean
}

const imgCache: ImgCacheInterface = {
  _cache: {},
  read(src?: string) {
    if (!src) return false

    if (!this._cache[src]) {
      this._cache[src] = new Promise((resolve) => {
        const img = new Image()

        img.onerror = () => {
          this._cache[src] = false
          resolve(this._cache[src])
        }

        img.onload = () => {
          this._cache[src] = true
          resolve(this._cache[src])
        }

        img.src = src
      }).then(() => {
        this._cache[src] = true
      })
    }

    if (this._cache[src] instanceof Promise) {
      throw this._cache[src]
    }

    return this._cache[src]
  },
}

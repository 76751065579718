import { customFetch } from "../../../libs/fetch/fetch";
import {
  GetUsersUserIdLessonsLessonIdRequest,
  GetUsersUserIdLessonsLessonIdResponse,
  GetUsersUserIdResponse,
  GetUsersUserIdWorkoutsWorkoutIdRequest,
  GetUsersUserIdWorkoutsWorkoutIdResponse,
} from "./usersTypes";

export async function getUsersUserIdLessonsLessonId({
  userId,
  lessonId,
}: GetUsersUserIdLessonsLessonIdRequest): Promise<GetUsersUserIdLessonsLessonIdResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/users/${userId}/lessons/${lessonId}`,
  });
}

export async function getUsersUserIdWorkoutsWorkoutId({
  userId,
  workoutId,
  isCustom,
}: GetUsersUserIdWorkoutsWorkoutIdRequest): Promise<GetUsersUserIdWorkoutsWorkoutIdResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/users/${userId}/workouts/${workoutId}`,
    params: { isCustom },
  });
}

export async function getUsersUserId(
  userId: number,
): Promise<GetUsersUserIdResponse> {
  return await customFetch({
    app: "trainer",
    version: "v1",
    path: `/users/${userId}`,
    params: { userId },
  });
}

import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";
import { css, Interpolation, Theme } from "@emotion/react";
import * as LabelPrimitive from "@radix-ui/react-label";

const Label = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} css={[labelCSS, styles]} {...props} />
));

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };

const labelCSS = css`
  color: inherit;
  cursor: inherit;
  ${colors.default.Gray};
  ${typography.Button2};
`;

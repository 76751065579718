import {
  forwardRef,
  HTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from "react";

import { css, Interpolation, Theme } from "@emotion/react";
import { colors } from "design-system/styles/colors";
import { typography } from "design-system/styles/typography/typography";

export const Root = forwardRef<
  HTMLTableElement,
  HTMLAttributes<HTMLTableElement> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <div css={tableWrapperCSS}>
    <table ref={ref} css={[tableCSS, styles]} {...props} />
  </div>
));

Root.displayName = "Table";

export const Header = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <thead ref={ref} css={[tableHeaderCSS, styles]} {...props} />
));

Header.displayName = "TableHeader";

export const Body = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <tbody ref={ref} css={[tableBodyCSS, styles]} {...props} />
));

Body.displayName = "TableBody";

export const Footer = forwardRef<
  HTMLTableSectionElement,
  HTMLAttributes<HTMLTableSectionElement> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <tfoot ref={ref} css={[tableFooterCSS, styles]} {...props} />
));

Footer.displayName = "TableFooter";

export const Row = forwardRef<
  HTMLTableRowElement,
  HTMLAttributes<HTMLTableRowElement> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <tr ref={ref} css={[tableRowCSS, styles]} {...props} />
));

Row.displayName = "TableRow";

export const Head = forwardRef<
  HTMLTableCellElement,
  ThHTMLAttributes<HTMLTableCellElement> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <th ref={ref} css={[tableHeadCSS, styles]} {...props} />
));

Head.displayName = "TableHead";

export const Cell = forwardRef<
  HTMLTableCellElement,
  TdHTMLAttributes<HTMLTableCellElement> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <td ref={ref} css={[tableCellCSS, styles]} {...props} />
));

Cell.displayName = "TableCell";

export const Caption = forwardRef<
  HTMLTableCaptionElement,
  HTMLAttributes<HTMLTableCaptionElement> & {
    styles?: Interpolation<Theme>;
  }
>(({ styles, ...props }, ref) => (
  <caption ref={ref} css={[tableCaptionCSS, styles]} {...props} />
));

Caption.displayName = "TableCaption";

const tableWrapperCSS = css`
  overflow: auto;
  width: 100%;
`;

const tableCSS = css`
  width: 100%;
  caption-side: bottom;
  table-layout: fixed;
  overflow: auto;
`;

const tableHeaderCSS = css`
  border-top: 2px solid ${colors.default.Black};

  tr {
    border-bottom: 1px solid ${colors.default.Black_a10};

    :hover {
      background-color: transparent;
    }
  }
`;

const tableBodyCSS = css``;

const tableFooterCSS = css`
  color: ${colors.default.Gray500};
  ${typography.Button2};
`;

const tableRowCSS = css`
  border-bottom: 1px solid ${colors.default.Black_a10};

  &[data-state="selected"] {
    background-color: ${colors.default.Black_a5};
  }

  cursor: pointer;

  :hover {
    background: ${colors.gray50};
  }

  &[data-state="disabled"] {
    cursor: not-allowed;

    td {
      color: ${colors.default.Gray500};
    }

    :hover {
      background-color: transparent;
    }
  }
`;

const tableHeadCSS = css`
  height: 42px;
  padding-right: 16px;
  text-align: left;
  vertical-align: middle;
  color: ${colors.default.Black};
  ${typography.Caption_B};

  &:has([role="checkbox"]) {
    padding-left: 8px;
    padding-right: 12px;
  }
`;

const tableCellCSS = css`
  width: 100%;
  height: 48px;
  padding-right: 16px;
  text-align: left;
  vertical-align: middle;
  color: ${colors.default.Gray};
  ${typography.Button2};

  &:has([role="checkbox"]) {
    padding-left: 8px;
    padding-right: 12px;
  }

  &:has([role="radio"]) {
    padding-left: 8px;
    padding-right: 12px;
  }
`;

const tableCaptionCSS = css`
  margin-top: 1rem;
  color: ${colors.default.Gray500};
  ${typography.Button1};
`;

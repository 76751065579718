/** @jsxImportSource @emotion/react */
import { createContext } from "react";
import { DialogRootProps } from "./Dialog";

interface DialogContext {
  variant?: DialogRootProps["variant"];
  isAlertDialog?: DialogRootProps["isAlertDialog"];
  isBorderRadius?: DialogRootProps["isBorderRadius"];
}

export default createContext<DialogContext | undefined>(undefined);

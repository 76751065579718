import {
  GetSigninKakaoOauthTokenRequest,
  GetSigninKakaoOauthTokenResponse,
} from "./siginTypes";
import { customFetch } from "../../../libs/fetch/fetch";

export async function getSignInKakaoOauthToken({
  code,
}: GetSigninKakaoOauthTokenRequest): Promise<GetSigninKakaoOauthTokenResponse> {
  return await customFetch(
    {
      app: "user",
      version: "v1",
      path: `/signin/kakao/oauth/token`,
      params: { code },
    },
    {
      method: "POST",
    },
  );
}
